import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getAllTransactions } from "~/redux/actions/stripe-actions";

// Utils
import { dateFormat } from "~/utils/date-format";
import { balanceFormatter } from "~/utils/helperFunctions";
import { TRANSACTION_STATUS } from "~/utils/constants";
import { MESSAGES } from "~/utils/message";

// Components
import Loader from "~/components/General/Loader";

// MUI Components
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Pagination,
  PaginationItem,
  Typography,
  Stack,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

// Icons
import { NoContactCIcon } from "~/assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveIcon from "@mui/icons-material/Remove";

// Styles
import "./styles.scss";

const TransactionListing = ({ setSelectedRows, selectedRows }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [bulkSelect, setBulkSelect] = useState(false);
  const [removeBulkSelect, setRemoveBulkSelect] = useState(false);
  const [rows, setRows] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const fetchedRows = useSelector(
    (state) => state.stripeReducers?.transactions.rows
  );

  const transactions = useSelector(
    (state) => state.stripeReducers?.transactions
  );

  const isLoading = useSelector(
    (state) => state.stripeReducers?.transactions?.loading
  );

  const open = Boolean(anchorEl);


  const selectAll = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((trans) => ({ ...trans, checked: true }));
    setRows(updatedRows);
    setSelectedRows([-1]);
  };

  const selectVisble = () => {
    setBulkSelect(true);
    const updatedRows = rows.map((trans) => ({ ...trans, checked: true }));
    setRows(updatedRows);
    const selectedRowIds = updatedRows.map((trans) => trans.id);
    setSelectedRows(selectedRowIds);
  };


  const selectNone = () => {
    setBulkSelect(false);
    setRemoveBulkSelect(false);
    const updatedRows = rows.map((trans) => ({ ...trans, checked: false }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  const selectRow = (event, selectedRow) => {
    event.stopPropagation();
    // Update the selectedRow object directly in the rows array

    const updatedRows = rows.map((row) =>
      row.id === selectedRow.id ? { ...row, checked: !row.checked } : row
    );
    // If the user selects all trans and then removes a single trans, update the selected rows array accordingly.
    if (selectedRows[0] === -1) {
      const filteredRows = rows.map((row) =>
        row.id === selectedRow.id
          ? { ...row, checked: !row.checked }
          : { ...row, checked: true }
      );
      const filteredRowsIds = filteredRows
        .map((trans) => trans.checked === true && trans.id)
        .filter(Boolean);
      setSelectedRows(filteredRowsIds);
      setRows(updatedRows);
      setRemoveBulkSelect(true);
      return;
    }

    setRows(updatedRows);

    const selectedRowIds = selectedRows.includes(selectedRow.id)
      ? selectedRows.filter((id) => id !== selectedRow.id) // Remove the ID if already selected
      : [...selectedRows, selectedRow.id]; // Add the ID if not selected
    // Update the selectedRows state with the new array
    setSelectedRows(selectedRowIds);
    setRemoveBulkSelect(true);
  };


  const bulkSelection = () => {
    const newBulkSelect = !bulkSelect;
    setBulkSelect(newBulkSelect);
    const updatedRows = rows.map((trans) => ({
      ...trans,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);
    const selectedRowIds = updatedRows.map((trans) => trans.id);
    newBulkSelect ? setSelectedRows(selectedRowIds) : setSelectedRows([]);
  };


  const removeBulkSelection = () => {
    const newBulkSelect = !removeBulkSelect;
    setRemoveBulkSelect(newBulkSelect);
    setBulkSelect(newBulkSelect);
    const updatedRows = rows.map((trans) => ({
      ...trans,
      checked: newBulkSelect,
    }));
    setRows(updatedRows);
    setSelectedRows([]);
  };

  useEffect(() => {
    setRows(fetchedRows);
  }, [fetchedRows]);

  useEffect(() => {
    setSelectedRows([]);
  }, [transactions.currentPage, transactions.perPage]);

  useEffect(() => {
    !selectedRows.length && setRemoveBulkSelect(false);
    (rows.length && selectedRows.length === rows.length) ||
    selectedRows[0] === -1
      ? (setBulkSelect(true), setRemoveBulkSelect(false))
      : setBulkSelect(false);
  }, [selectedRows, rows]);

  useEffect(() => {
    setRows(fetchedRows);
  }, [fetchedRows]);

  const columns = [
    {
      field: " ",
      headerName: (
        <Box className="checkboxHeader">
          {removeBulkSelect ? (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<RemoveIcon />}
              checked={removeBulkSelect}
              onClick={removeBulkSelection}
            />
          ) : (
            <Checkbox
              className="bulkCheck"
              icon={<Box className="bulkNotchecked" />}
              checkedIcon={<DoneIcon />}
              checked={bulkSelect}
              onClick={bulkSelection}
            />
          )}
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableRipple
            className="bulkActionsDropIcon"
          >
            <KeyboardArrowDownIcon
              sx={{
                color: anchorEl ? "#ed5c2f" : "inherit",
                transform: anchorEl && "rotate(180deg)",
              }}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={() => setAnchorEl(null)}
            onClick={() => setAnchorEl(null)}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            MenuListProps={{
              className: "bulkMenuOptions",
            }}
          >
            <MenuItem
              onClick={transactions.count > 0 ? selectAll : undefined}
              className={selectedRows[0] === -1 ? "highlight" : ""}
            >
              {MESSAGES.ACCOUNT.WALLET.EXPORT.SELECT_ALL_ITEMS} ({transactions.count})
            </MenuItem>
            <MenuItem onClick={selectNone}>
              {MESSAGES.ACCOUNT.WALLET.EXPORT.SELECT_NONE}
            </MenuItem>
            <MenuItem onClick={selectVisble}>
              {MESSAGES.ACCOUNT.WALLET.EXPORT.SELECT_VISIBLE_ITEMS}
            </MenuItem>
          </Menu>
        </Box>
      ),
      width: 75,
      sortable: false,
      renderCell: (params) => (
        <Box className="checkboxRow">
          <Checkbox
            onClick={(event) => selectRow(event, params.row)}
            className="bulkCheck"
            icon={<Box className="bulkNotchecked" />}
            checkedIcon={<DoneIcon />}
            checked={params.row.checked}
          />
        </Box>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Typography>{dateFormat(params.row.createdAt, true)}</Typography>
      ),
    },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => <Typography>{params.row.id}</Typography>,
    },
    {
      field: "description",
      headerName: "Description",
      type: "number",
      width: 450,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => (
        <Box className="descriptionWrapper">
          <Tooltip title={params.row.remarks}>
          <Typography>{params.row.remarks}</Typography>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      height: 550,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => {
        const balance = balanceFormatter(params.row.amount);
        return (
          <Typography>
            <span className="icon-color">
              {TRANSACTION_STATUS[params.row.type]}
            </span>{" "}
            {balance}
          </Typography>
        );
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 200,
      sortable: false,
      flex: window.innerWidth >= 1350 ? 1 : 0,
      renderCell: (params) => {
        const balance = balanceFormatter(params.row.meta.newBalance);
        return <Typography>{balance}</Typography>;
      },
    },
  ];

  useEffect(() => {
    handleGetTransactions(transactions.currentPage, transactions.perPage);
  }, []);
  const handleGetTransactions = (page, perPage) => {
    dispatch(getAllTransactions(page, perPage));
  };

  const handleChangePage = (event, page) => {
    handleGetTransactions(page, transactions.perPage);
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      style={{
        marginTop: "25px",
        width: "100%",
      }}
      className="fundsTableWrapper"
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSizeOptions={[20, 50, 100]}
        paginationMode="server"
        hideFooterSelectedRowCount
        loading={isLoading}
        hideFooterPagination
        rowSelection={false}
        getRowId={(row) => row.id}
        disableColumnMenu={true}
        className="fundsTableGrid"
        slots={{
          noRowsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.ACCOUNT.WALLET.STRIPE.NO_TRANSACTION}
            </Stack>
          ),
          noResultsOverlay: () => (
            <Stack className="noRowsTextContacts">
              <img src={NoContactCIcon} alt="NoContact" />
              {MESSAGES.NO_ROWS_TEXT}
            </Stack>
          ),
          loadingOverlay: Loader,
        }}
      />
      <Box className="fundsPaginationWrapper">
        <Pagination
          count={Math.ceil(transactions.count / transactions.perPage)}
          variant="outlined"
          shape="rounded"
          onChange={handleChangePage}
          page={transactions.currentPage}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: ArrowBackIosNewIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
            />
          )}
        />

        <Typography>
          Showing {transactions.from} to {transactions.to} of{" "}
          {transactions.count} results
        </Typography>
      </Box>
    </div>
  );
};

export default TransactionListing;
