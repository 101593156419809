import React, { useEffect, useRef, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import {
  fetchAdminUsers,
  fetchAdminUsersFilters,
  userBulkActions,
} from "~/redux/actions/admin-users-actions";
import { CLEAR_ADMIN_USERS_FILTERS } from "~/redux/actions/action-types";
import { failure, success } from "~/redux/actions/snackbar-actions";

// Utils
import { DEFAULT_PAGINATION } from "~/utils/constants";
import { MESSAGES } from "~/utils/message";
import { isNumberString } from "~/utils/helperFunctions";

// Components
import Table from "./Table";
import AdvanceFilters from "./Filters";
import ActionModal from "./ActionModal";
import AddFundsModal from "./AddFundsModal";
import NoAdminUsersScreen from "./NoAdminUsersScreen";
import ExportModal from "~/components/Contacts/ExportModal";

// MUI Components
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

// Icons
import {
  ContactSearchCIcon,
  ActiveFilterCIcon,
  InActiveFilterCIcon,
  ExportCIcon,
} from "~/assets/images";

// Styles
import "./styles.scss";


const AdminUsers = () => {
  const { userId } = useParams();
  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const [isMounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [searchApplied, setSearchApplied] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [openAdvanceFilters, setOpenAdvanceFilters] = useState(false);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [openFundsModal, setOpenFundsModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const childRef = useRef();
  const dispatch = useDispatch();

  const applyFilters = useSelector(
    (state) => state.adminUsersReducers.applyFilters
  );
  const filters = useSelector((state) => state.adminUsersReducers.filters);
  const adminUsers = useSelector(
    (state) => state.adminUsersReducers.totalUsersInDB
  );
  const filterSize = useSelector(
    (state) => state.adminUsersReducers.filterSize
  );

  const handleSearch = () => {
    if (search) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getAdminUsers();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getAdminUsers = (userId) => {
    let payload = {
      pagination,
      search: userId ? userId : search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchAdminUsers(payload));
  };

  const exportUsers = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "export",
        search: userId ? userId : search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await userBulkActions(payload);
      getAdminUsers();
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error?.response?.data?.message));
    } finally {
      setLoading(false);
      setExportModal(false);
    }
  };

  useEffect(() => {
    setIsMounted(true);
    userId && setSearch(userId);
    userId && setSearchApplied(true);
    userId ? getAdminUsers(userId) : getAdminUsers();
    dispatch(fetchAdminUsersFilters());
    return () => {
      dispatch({ type: CLEAR_ADMIN_USERS_FILTERS });
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      getAdminUsers();
    }
  }, [pagination.page, pagination.pageSize, filterSize, filters]);

  useEffect(() => {
    if (!search && isMounted) {
      setSearchApplied(false);
      setSearch("");
      getAdminUsers();
    }
  }, [search]);

  const noAdminUsers = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoAdminUsersScreen />
      </Container>
    );
  };

  if (adminUsers === 0 && adminUsers !== null) {
    return noAdminUsers();
  }

  return (
    <>
      <Container maxWidth="xxl">
        <div className="adminUserMain">
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="searchBtnWrapper"
            >
              <Box className="searchWrapper">
                <Input
                  className={`searchInput ${searchApplied ? "searchApplied" : ""
                    }`}
                  type="text"
                  value={search}
                  onChange={(e) => {
                    if (
                      e.target.value.length > 9 &&
                      isNumberString(e.target.value)
                    )
                      return;
                    e.target.value == " "
                      ? setSearch(e.target.value.trim())
                      : setSearch(e.target.value);
                  }}
                  onKeyDown={searchKeyDown}
                  placeholder={
                    MESSAGES.ADMIN_DASHBOARD.USERS.SEARCH_PLACE_HOLDER
                  }
                  endAdornment={
                    <InputAdornment
                      position="end"
                      className="searchInputAdornment"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        className="searchIcon"
                        onClick={handleSearch}
                      >
                        <img src={ContactSearchCIcon} alt="search" />
                      </IconButton>
                      {searchApplied && (
                        <HighlightOffIcon
                          onClick={() => {
                            setSearch("");
                          }}
                          className="clearSerach"
                        />
                      )}
                    </InputAdornment>
                  }
                />
                <Box className="filterBtnWrapper">
                  <img
                    onClick={() => {
                      setOpenAdvanceFilters(true);
                    }}
                    className={
                      filterSize > 0
                        ? "filterImage activeFilter"
                        : "filterImage noFilter"
                    }
                    src={
                      filterSize > 0 ? ActiveFilterCIcon : InActiveFilterCIcon
                    }
                    alt="Filter"
                  />
                  {filterSize > 0 && (
                    <Typography className="filtersCounter">
                      {filterSize}
                    </Typography>
                  )}
                </Box>
                {filterSize > 0 && (
                  <Button
                    onClick={() => {
                      childRef.current.clearFilters();
                    }}
                    className="clearFilterBtn"
                  >
                    {MESSAGES.ADMIN_DASHBOARD.USERS.CLEAR_FILTER_BTN_TEXT}
                  </Button>
                )}
              </Box>
              <Box className="adminUserActionsBtnWrapper">
                {selectedRows.length > 0 && (
                  <>
                    <Button
                      onClick={() => {
                        setExportModal(true);
                      }}
                    >
                      <img src={ExportCIcon} alt="actions" />
                      <Typography className="btnTxt">
                        {
                          MESSAGES.ADMIN_DASHBOARD.USERS.EXPORT
                            .EXPORT_BTN_TEXT
                        }
                      </Typography>
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
          <Table
            pagination={pagination}
            updatePagination={setPagination}
            setOpenActionModal={setOpenActionModal}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            setOpenFundsModal={setOpenFundsModal}
            searchApplied={searchApplied}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
          />
          <AdvanceFilters
            open={openAdvanceFilters}
            handleCloseFilter={() => setOpenAdvanceFilters(false)}
            ref={childRef}
            updatePagination={setPagination}
          />
          <ActionModal
            openModal={openActionModal}
            handleCloseModal={() => setOpenActionModal(false)}
            selectedUser={selectedUser}
            getAdminUsers={getAdminUsers}
          />
          <AddFundsModal
            open={openFundsModal}
            handleClose={() => {
              setOpenFundsModal(false);
            }}
            selectedUser={selectedUser}
          />
          <ExportModal
            open={exportModal}
            handleExport={exportUsers}
            handleClose={() => {
              setExportModal(false);
            }}
            loading={loading}
            header={
              selectedRows[0] === -1 || selectedRows.length > 1
                ? MESSAGES.ADMIN_DASHBOARD.USERS.EXPORT.MULTI_EXPORT_HEADER
                : MESSAGES.ADMIN_DASHBOARD.USERS.EXPORT.SINGLE_EXPORT_HEADER
            }
            message={
              selectedRows[0] === -1 || selectedRows.length > 1
                ? MESSAGES.ADMIN_DASHBOARD.USERS.EXPORT.EXPORT_USERS
                : MESSAGES.ADMIN_DASHBOARD.USERS.EXPORT.EXPORT_SINGLE_USERS
            }
            subject={MESSAGES.ADMIN_DASHBOARD.USERS.EXPORT.EXPORT_MESSAGE}
          />
        </div>
      </Container>
    </>
  );
};

export default AdminUsers;
