/**
 * @Author: Junaid Atari junaid.attari@invozone.dev
 * @Date: 2024-12-20 12:33:37
 * @LastEditors: Junaid Atari junaid.attari@invozone.dev
 * @LastEditTime: 2024-12-20 12:33:39
 * @FilePath: src/components/HireDesigner/index.jsx
 * @Description: 这是默认设置,可以在设置》工具》File Description中进行配置
 */
import React, { useState } from "react";

// hooks
import { useDispatch, useSelector } from "react-redux";

// actions
import { failure, success } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import request from "~/utils/request";
import { EMAIL_REGEX } from "~/utils/constants";

// Components
import GenericInput from "~/components/HireDesigner/GenericInput";
import GenericAutocomplete from "../General/Autocomplete";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { CrossModalCIcon } from "~/assets/images";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const index = (props) => {
  const { openModal, handleCloseModal } = props;
  const [queryTitle, setQueryTitle] = useState("");
  const [queryEmail, setQueryEmail] = useState("");
  const [queryFile, setQueryFile] = useState([]);
  const [queryComments, setQueryComments] = useState("");
  const [queryProductType, setQueryProductType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    title: "",
    email: "",
    productType: "",
    comments: "",
  });

  const dispatch = useDispatch();
  const products = useSelector((state) => state.productReducers.products);
  const productTypes = products.map((product) => ({
    id: product.id,
    label: product.productType + ` (${product.name})`,
  }));

  const handleTitleChange = (event) => {
    setQueryTitle(event.target.value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      title: event.target.value.trim() && "",
    }));
  };

  const handleEmailChange = (event) => {
    setQueryEmail(event.target.value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      email: event.target.value.trim() && "",
    }));
  };

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setQueryFile(filesArray);
  };

  const handleFileRemove = (file) => {
    const updatedFiles = queryFile.filter((f) => f.name !== file.name);
    setQueryFile(updatedFiles);
  };

  const handleCommentsChange = (event) => {
    setQueryComments(event.target.value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      comments: event.target.value.trim() && "",
    }));
  };

const validateForm = (formData, setFormErrors) => {
    const errors = {};

    if (!formData.queryTitle.trim()) {
        errors.title = "Title is required";
    }

    if (!formData.queryProductType) {
        errors.productType = "Product Type is required";
    }

    if (!formData.queryComments.trim()) {
        errors.comments = "Comments are required";
    }

    if (!formData.queryEmail.trim()) {
        errors.email = "Email is required";
    } else if (!EMAIL_REGEX.test(formData.queryEmail.trim())) {
        errors.email = "Email is not valid";
    }

    setFormErrors(errors);

    // Return true if there are no errors, false otherwise
    return Object.keys(errors).length === 0;
};

  const resetForm = () => {
    setQueryTitle("");
    setQueryEmail("");
    setQueryFile([]);
    setQueryComments("");
    setQueryProductType(null);
    setFormErrors({
      title: "",
      email: "",
      productType: "",
      comments: "",
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = {
        queryTitle,
        queryProductType,
        queryComments,
        queryEmail,
    };

    const isValid = validateForm(formData, setFormErrors);
    if (!isValid) {
        setIsLoading(false);
        return;
    }
    
    try{
      const form = new FormData();
      form.append("title", queryTitle);
      form.append("productId", queryProductType?.id);
      form.append("comments", queryComments);
      form.append("email", queryEmail);
  
      queryFile.forEach((file) => {
        form.append("files", file);
      });

      const response = await request.post(`sdk/custom-template-queries/create`, form);
      setIsLoading(false);
      console.log(response, "response");
      dispatch(success(response.data.message));
      resetForm();
      handleCloseModal();
    } catch (error) {
      dispatch(failure(error.response?.data?.message ||
            error?.data?.message ||
            MESSAGES.GENERAL_ERROR));
      console.log(error, "error");
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="hireModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            resetForm();
            handleCloseModal();
          }}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="close" />
        </IconButton>
        <DialogContent className="hireModalContent" dividers>
          <Box className="hireHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.HIRE_DESIGNER.TITLE}
            </Typography>
          </Box>
          <Box className="hireForm">
            <GenericInput
              label="Template Name*"
              placeholder="Template Name"
              type="text"
              value={queryTitle}
              onChange={handleTitleChange}
              error={formErrors.title}
            />
            <GenericInput
              label="Email*"
              placeholder="Email"
              type="email"
              value={queryEmail}
              onChange={handleEmailChange}
              error={formErrors.email}
            />
            <Box className="hireProductType">
              <Box className="productTypeLabel">
                <Typography>Select Product Type*</Typography>
              </Box>
              <GenericAutocomplete
                label="Select Product Type*"
                placeholder="Select Product Type"
                options={productTypes}
                setSelectedValue={setQueryProductType}
                selectedValue={queryProductType}
                search={() => {}}
                updateErrors={() => {}}
                name={"hireDesigner"}
                filterAutoComplete
                error={formErrors.productType && !queryProductType}
              />
              {formErrors.productType && !queryProductType ? (
                <p className="error" style={{fontWeight: 600}}><sup>*</sup>Product Type is required</p>
              ) : null}
            </Box>
            <GenericInput
              label="Upload Design Files"
              placeholder="Upload Design Files"
              type="file"
              fileUploader={true}
              onChange={handleFileChange}
              value={queryFile}
              handleFileRemove={handleFileRemove}
            />
            <GenericInput
              label="Comments*"
              placeholder="Comments..."
              type="textarea"
              value={queryComments}
              onChange={handleCommentsChange}
              error={formErrors.comments}
            />
          </Box>
          <Box className="hireModalBtns">
            <Button onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? <CircularProgress sx={{
                color: "#fff",
                width: "20px",
                height: "20px",
              }} /> : MESSAGES.HIRE_DESIGNER.SUBMIT_BUTTON}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default index;
