import React from "react";

// Hooks
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

// Actions
import { failure, success } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import Loader from "../../../General/Loader";

// MUI Components
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// Icons
import CloseIcon from "../../../../assets/images/modal/modal-cross";
import FileIcon from "../../../../assets/images/admin/file.svg";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const QueryDetails = (props) => {
  const { open, onClose, data, loading } = props;
  const dispatch = useDispatch();

  const getFileExtension = (filename) => filename.split(".").pop();

  const downloadFile = async (fileUrl) => {
    if (!fileUrl) {
      dispatch(failure(MESSAGES.ADMIN_DASHBOARD.QUERIES.URL_NOT_FOUND));
      return;
    }
  
    try {
      const response = await fetch(fileUrl, {
        method: "GET",
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const blob = await response.blob(); // Get the file as a blob
      const blobUrl = window.URL.createObjectURL(blob); // Create a URL for the blob
      const link = document.createElement("a");
  
      link.href = blobUrl;
      link.download = fileUrl.split("/").pop(); // Extract file name from URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  
      // Cleanup the blob URL
      window.URL.revokeObjectURL(blobUrl);
      dispatch(success(MESSAGES.ADMIN_DASHBOARD.QUERIES.FILE_DOWNLOADED));
    } catch (error) {
      dispatch(failure(MESSAGES.ADMIN_DASHBOARD.QUERIES.FILE_DOWNLOAD_FAILED));
    }
  };
  

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="queryDetailsWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        className="closeModalButton"
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className="queryDetailsWrapper" dividers>
        {loading ? (
          <Box className="loaderWrapper">
            <Loader />
          </Box>
        ) : (
          <>
            <Box className="queryHeader">
              <Typography gutterBottom variant="h4">
                {data?.meta?.templateTitle}
              </Typography>
            </Box>
            <Box className="queryDetails">
              <Box className="infoRow">
                <Typography>Amount</Typography>
                <Typography>${data?.amount ? data?.amount : "-"}</Typography>
              </Box>
              <Box className="infoRow">
                <Typography>URL</Typography>
                {
                  data?.url ? 
                  <Typography className="urlLink" onClick={() => window.open(data?.url, '_blank')}>{data?.url}</Typography> : <Typography>-</Typography>
                }
              </Box>
              <Box className="commentsDiv">
                <Typography>Comments</Typography>
                <Box className="commentsWrapper">
                  <Box className="commentBox">
                    <Typography className="content">
                      {data?.comments ? data?.comments : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="filesDiv">
                <Typography>Files</Typography>
                {data?.files?.length > 0 ? <Box className="filesWrapper">
                  {data?.files.map((file, index) => {
                    return (
                      <Box className="fileName" key={index}>
                        <Box className="fileBox" onClick={() => downloadFile(file)}>
                          <img src={FileIcon} alt="file" />
                          <Typography>{getFileExtension(file)}</Typography>
                        </Box>
                        <Typography className="name" key={file?.id}>
                          {file.split("/").pop()}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box> : 
                <>
                <Typography className="noFileUploaded">No files uploaded</Typography>
                </>}
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default QueryDetails;
