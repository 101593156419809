// noinspection JSVoidFunctionReturnValueUsed

import { useEffect } from "react";

// Third Party Integrations
import TemplateBuilderSDK from "@openlettermarketing/olc-react-sdk";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Actions
import { failure } from "~/redux/actions/snackbar-actions.js";
import { getOneTemplate, getTemplatesByTab } from '~/redux/actions/template-builder.js';
import {
  getOneDesignerTemplateQuery,
  updateTemplateQuery,
} from "~/redux/actions/admin-custom-designer-query.js";

// Utils
import { getUserToken } from "~/utils/auth.js";

// Styles
import "@blueprintjs/core/lib/css/blueprint.css";
import "./styles.scss";

const TemplateBuilder = () => {
  let { id } = useParams();
  let templateBuilderInstance = null;
  const isSandboxMode = import.meta.env.VITE_APP_ENV !== "production";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isPropertyAddressEnabled = useSelector(
    (state) =>
      state?.generalSettingsReducers?.generalSettings
        ?.propertyAddressStrictness === "Include" || false
  );

  const redirectToOlc = () => {
    destroyTemplateBuilder();
    setTimeout(() => {
      navigate("/admin/designer-queries");
    }, 1000);
  };

  /**
   * @param {FormData} formData
   */
  const onUpdate = async (formData) => {
    await updateTemplateQuery(id, formData);
    redirectToOlc();
  };

  const setEditingTemplate = async () => {
    const response = await getOneDesignerTemplateQuery(id);

    const { isUpdated = false } = response?.data?.data ?? {};

    if (response?.data?.data?.template) {
      createTemplateBuilder(response?.data?.data?.template, isUpdated);
    } else {
      redirectToOlc();
    }
  };

  const getAllTemplatesByTab = async (payload) => {
    try {
      const tempates = await getTemplatesByTab(payload);
      if (tempates.status === 200) {
        return tempates?.data?.data;
      } else {
        return null;
      }
    } catch (error) {
      dispatch(failure(error?.data?.message || "Internal Server Error"));
      return null;
    }
  };

  const getTemplateById = async (_id) => {
    try {
      const tempate = await getOneTemplate(_id);
      if (tempate.status === 200) {
        return tempate?.data?.data;
      }
    } catch (error) {
      console.log(error);
      dispatch(failure(error?.data?.message || "Internal Server Error@@@@"));
      return false;
    }
  };

  const destroyTemplateBuilder = async () => {
    await templateBuilderInstance?.destroy?.();
    templateBuilderInstance = null;
  };

  const createTemplateBuilder = (
    editingTemplateData = null,
    isUpdated = false
  ) => {
    const props = {
      container: document.getElementById("template-builder-container"),
      secretKey: import.meta.env.VITE_APP_PLOTNO_API_KEY,
      publicApiKey: getUserToken(),
      platformName: "OLC",
      templateBuilderRoute: `admin/designer-queries/template/${id}`,
      allowPropertyFields: isPropertyAddressEnabled,
      allowSenderFields: true,
      templateGalleryModal: true,
      sandbox: isSandboxMode,
      onReturnAndNavigate: redirectToOlc,
      onGetTemplates: getAllTemplatesByTab,
      onGetOneTemplate: getTemplateById,
      onSubmit: editingTemplateData ? onUpdate : () => {},
    };

    if (!isUpdated) {
      props.designerTemplateQuery = editingTemplateData;
    } else {
      props.olcTemplate = editingTemplateData;
    }

    templateBuilderInstance = new TemplateBuilderSDK(props);
  };

  useEffect(() => {
    if (id) {
      setEditingTemplate();
    }

    return () => {
      destroyTemplateBuilder();
    };
  }, []);

  return <div id="template-builder-container" />;
};

export default TemplateBuilder;
