import { useEffect, useRef } from 'react';

const useBroadcastChannel = (channelName, onMessage) => {
  const channelRef = useRef(null);

  useEffect(() => {
    channelRef.current = new BroadcastChannel(channelName);

    // Set up the message listener
    const handleMessage = (event) => {
      if (onMessage) {
        onMessage(event.data);
      }
    };
    channelRef.current.addEventListener('message', handleMessage);

    // Clean up on component unmount
    return () => {
      if (channelRef.current) {
        channelRef.current.removeEventListener('message', handleMessage);
        channelRef.current.close();
      }
    };
  }, [channelName, onMessage]);

  // Function to send a message
  const broadcastMessage = (message="") => {
    if (channelRef.current) {
      channelRef?.current?.postMessage(message);
    }
  };

  return { broadcastMessage };
};

export default useBroadcastChannel;
