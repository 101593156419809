import React from "react";

// Utils
import { MESSAGES } from "~/utils/message.js";

// MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { ExportModalCIcon, ModalCrossCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ConfirmDialog = (props) => {
  const {
    successButtonName,
    heading,
    subMessage,
    message,
    icon,
    currentAction,
    isExport = false,
  } = props.dialog;
  const { handleOk, handleClose, loading, open } = props;
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="orderautoFundModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="autoFundModalContent">
          {isExport ? (
            <ExportModalCIcon />
          ) : (
            icon && <img className="mainImg" src={icon} alt="delete"/>
          )}
          <Box className="autoFundHeader">
            <Typography gutterBottom variant="h4">
              {heading}
            </Typography>
          </Box>
          <Box className="amountInputWrapper">
            <Typography className="boldTxt">{message}</Typography>
            <Typography className="txt">{subMessage}</Typography>
          </Box>
          <Box></Box>
          <Box className="autoFundModalBtns">
            <Button onClick={handleClose}>
              {MESSAGES.ACCOUNT.WALLET.STRIPE.CANCEL}
            </Button>
            <Button
              disabled={loading}
              onClick={handleOk}
              sx={{
                maxWidth:
                  currentAction === "payAndProcess"
                    ? "128px !important"
                    : "100px !important",
              }}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : successButtonName ? (
                successButtonName
              ) : (
                "Ok"
              )}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default ConfirmDialog;
