import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "../../../../redux/actions/snackbar-actions";
import { regeneratePDF, fetchAdminBatches } from "../../../../redux/actions/admin-batch-actions";

// Utils
import { MESSAGES } from "../../../../utils/message";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import CloseIcon from "../../../../assets/images/modal/modal-cross";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const RegeneratePDFModal = (props) => {
  const { openModal, handleCloseModal, batchId, searchApplied, pagination  } = props;
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();


  const onSubmit = async () => {
    try {
      setLoading(true);
      const response = await dispatch(regeneratePDF(batchId));
      if (response.status === 200) {
        dispatch(success(response?.data?.message));
        dispatch(
          fetchAdminBatches({
            ...(searchApplied ? { search: batchId } : {}),
            pagination: { ...pagination },
          })
        );
        handleCloseModal();
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message || error?.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="userActionModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers className="userActionModalContent">
        <Box className="deleteHeadings">
          <Typography gutterBottom variant="h4">
            {MESSAGES.ADMIN_DASHBOARD.BATCHES.REGENERATE_PDF.TITLE}
          </Typography>
          <Typography gutterBottom>
            {MESSAGES.ADMIN_DASHBOARD.BATCHES.REGENERATE_PDF.HEADING}
          </Typography>
        </Box>
        <Box className="userActionModalBtns">
          <Button onClick={handleCloseModal}>
            {MESSAGES.ADMIN_DASHBOARD.BATCHES.REGENERATE_PDF.CANCEL_BUTTON}
          </Button>
          <Button onClick={onSubmit}>
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.ADMIN_DASHBOARD.BATCHES.REGENERATE_PDF.SUBMIT_BUTTON
            )}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default RegeneratePDFModal;
