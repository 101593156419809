import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

// Third Party Libraries
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Hooks
import { useDispatch, useSelector } from "react-redux";

//Action
import {
  SET_CONTACTS_FILTERS,
  CLEAR_CONTACTS_FILTERS,
} from "~/redux/actions/action-types";
import { failure } from "~/redux/actions/snackbar-actions";

//Utils
import { MESSAGES } from "~/utils/message";
import {
  DEFAULT_PAGINATION,
  ADDRESS_STATUS,
  MAILED_STATUS,
} from "~/utils/constants";
import {
  dateProtector,
  handleRawChangeForDatePicker,
} from "~/utils/date-format";

// Components
import MultiSelect from "./MultiSelect";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Typography from "@mui/material/Typography";
import { Box, Chip, Grid, InputLabel } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Icons
import { ModalCrossCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AdvanceFilters = forwardRef((props, ref) => {
  const { handleCloseFilter, open, updatePagination } = props;
  const [selectedMailedStatus, setSelectedMailedStatus] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedAddressStatus, setSelectedAddressStatus] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [createdDateRange, setCreatedDateRange] = useState([null, null]);
  const [qrCodeDateRange, setQrCodeDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [startCreatedDate, endCreatedDate] = createdDateRange;
  const [startQrCodeDate, endQrCodeDate] = qrCodeDateRange;
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [createdDatepickerOpen, setCreatedDatepickerOpen] = useState(false);
  const [qrCodeScanDatepickerOpen, setQrCodeScanDatepickerOpen] = useState(false);

  const [sortedTags, setSortedTags] = useState([]);

  const dispatch = useDispatch();

  const tags = useSelector((state) => state.contactsReducers.labels);

  const perPage = useSelector(
    (state) => state.contactsReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  const filters = useSelector((state) => state.contactsReducers.filters);

  const onClose = () => {
    clearFilters(true);
  };

  const clearFilters = (persist = false) => {
    setSelectedMailedStatus([]);
    setSortedTags(
      tags.map((tag) => {
        return {
          ...tag,
          disabled: false,
        };
      })
    );
    setSelectedTags([]);
    setSelectedAddressStatus([]);
    setDateRange([null, null]);
    setCreatedDateRange([null, null]);
    setQrCodeDateRange([null, null]);
    !persist ? dispatch({ type: CLEAR_CONTACTS_FILTERS }) : undefined;
    handleCloseFilter();
  };

  const removeTag = (tag) => {
    setSelectedTags((selectedTags) =>
      selectedTags.filter((item) => {
        return item !== tag;
      })
    );
    setSortedTags(
      sortedTags.map((e) => {
        return {
          ...e,
          disabled: false,
        };
      })
    );
  };

  const handleTagsSelection = (selectedTag) => {
    if (Array.isArray(selectedTag)) {
      if (selectedTag.includes(-1)) {
        setSortedTags(
          sortedTags.map((e) => {
            return {
              ...e,
              ...(e.id !== -1 && { disabled: true }),
            };
          })
        );
        setSelectedTags(selectedTag.filter((e) => e === -1));
      } else {
        setSortedTags(
          sortedTags.map((e) => {
            return {
              ...e,
              disabled: false,
            };
          })
        );
        setSelectedTags(selectedTag.filter((e) => e !== -1));
      }
    }
  };

  const applyFilters = () => {
    const lastMailedDate =
      startDate && endDate
        ? {
            startDate: startDate.toISOString().slice(0, 10),
            endDate: endDate.toISOString().slice(0, 10),
          }
        : null;

    if (startDate && endDate === null) {
      dispatch(failure(MESSAGES.CONTACTS.END_DATE_REQUIRED));
      return;
    }

    const createdDate =
      startCreatedDate && endCreatedDate
        ? {
            startDate: startCreatedDate.toISOString().slice(0, 10),
            endDate: endCreatedDate.toISOString().slice(0, 10),
          }
        : null;

    
    const qrCodeScanDate =
      startQrCodeDate && endQrCodeDate
      ? {
          startDate: startQrCodeDate.toISOString().slice(0, 10),
          endDate: endQrCodeDate.toISOString().slice(0, 10),
        }
      : null;

    if (startQrCodeDate && endQrCodeDate === null) {
      dispatch(failure(MESSAGES.CONTACTS.END_DATE_REQUIRED));
      return;
    }

    let payload = {
      applyFilters: true,
      filters: {
        tags: selectedTags,
        addressStatus: selectedAddressStatus,
        lastMailedStatus: selectedMailedStatus,
        lastMailedDate: lastMailedDate,
        createdAt: createdDate,
        lastQrScannedDate: qrCodeScanDate,
      },
    };

    const filtersWithValues = Object.keys(payload.filters).filter((key) => {
      const value = payload.filters[key];
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });

    payload.filterSize = filtersWithValues.length;

    updatePagination({
      pageSize: perPage,
      page: 1,
    });

    if (filtersWithValues.length <= 0) {
      dispatch(failure(MESSAGES.CONTACTS.FILTERS_REQUIRED));
      return;
    }

    dispatch({ type: SET_CONTACTS_FILTERS, payload: payload });
    onClose();
  };

  useImperativeHandle(ref, () => ({
    clearFilters() {
      clearFilters();
    },
  }));

  useEffect(() => {
    clearFilters();
  }, [currentMode]);

  useEffect(() => {
    if (Object.values(filters).length) {
      setSelectedMailedStatus(filters.lastMailedStatus);
      filters.tags.includes(-1)
      ? (setSortedTags(
          sortedTags.map((e) => ({
            ...e,
            ...(e.id !== -1 && { disabled: true }),
          }))
        ),
        setSelectedTags(filters.tags.filter((e) => e === -1)))
      : setSelectedTags(filters.tags);
      setSelectedAddressStatus(filters.addressStatus);
      filters.lastMailedDate
        ? setDateRange([
            new Date(filters?.lastMailedDate?.startDate),
            new Date(filters?.lastMailedDate?.endDate),
          ])
        : undefined;
      filters.createdAt
        ? setCreatedDateRange([
            new Date(filters?.createdAt?.startDate),
            new Date(filters?.createdAt?.endDate),
          ])
        : undefined;
      filters.lastQrScannedDate
        ? setQrCodeDateRange([
          new Date(filters?.lastQrScannedDate?.startDate),
          new Date(filters?.lastQrScannedDate?.endDate),
        ])
      : undefined;
    }
  }, [open]);

  useEffect(() => {
    const newTags = tags.sort((a, b) => a?.title.localeCompare(b?.title));
    newTags.unshift({
      id: -1,
      title: MESSAGES.CONTACTS.ADVANCE_FILTERS.NO_TAGS_CONTACTS,
      });
    setSortedTags(
      newTags.map((tag) => {
        return {
          ...tag,
          disabled: false,
        };
      })
    );
  }, [tags]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="contactsFilterModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="filterModalContent" dividers>
          <Box className="filterHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.CONTACTS.ADVANCE_FILTERS.HEADING}
            </Typography>
          </Box>
          <Box className="filterTagsWrapper">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel className="tagsLabel">
                  {MESSAGES.CONTACTS.ADVANCE_FILTERS.TAGS_LABEL}
                </InputLabel>
                <MultiSelect
                  component={"tag"}
                  options={sortedTags}
                  selectedValue={selectedTags}
                  setSelectedValue={handleTagsSelection}
                  placeholder={MESSAGES.CONTACTS.ADVANCE_FILTERS.SEARCH_TAG}
                  noOptionText={MESSAGES.CONTACTS.ADVANCE_FILTERS.NO_SEARCH_TAG}
                />
                {selectedTags.length > 0 && (
                  <Box className="selectedTagsWrapper">
                    {selectedTags.map((selectedTag) => {
                      return (
                        <Box className="tagContent" key={selectedTag}>
                          <Chip
                            className="tagChip"
                            label={
                              sortedTags.find((item) => item.id === selectedTag)
                                ?.title
                            }
                          />
                          <HighlightOffIcon
                            onClick={() => removeTag(selectedTag)}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="addressMailWrapper">
                  <Box className="selectAddress">
                    <InputLabel className="addressLabel">
                      {MESSAGES.CONTACTS.ADVANCE_FILTERS.ADDRESS_STATUS}
                    </InputLabel>
                    <MultiSelect
                      options={ADDRESS_STATUS}
                      selectedValue={selectedAddressStatus}
                      setSelectedValue={setSelectedAddressStatus}
                      placeholder={
                        MESSAGES.CONTACTS.ADVANCE_FILTERS.ADDRESS_STATUS
                      }
                      noOptionText={
                        MESSAGES.CONTACTS.ADVANCE_FILTERS.NO_ADDRESS_STATUS
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="addressMailWrapper">
                  <Box className="selectAddress">
                    <InputLabel className="addressLabel">
                      {MESSAGES.CONTACTS.ADVANCE_FILTERS.MAILED_STATUS}
                    </InputLabel>
                    <MultiSelect
                      options={MAILED_STATUS}
                      selectedValue={selectedMailedStatus}
                      setSelectedValue={setSelectedMailedStatus}
                      placeholder={
                        MESSAGES.CONTACTS.ADVANCE_FILTERS.MAILED_STATUS
                      }
                      noOptionText={
                        MESSAGES.CONTACTS.ADVANCE_FILTERS.NO_MAILED_STATUS
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="mailedDate">
                  <Box className="selectDate">
                    <InputLabel className="dateLabel">
                      {MESSAGES.CONTACTS.ADVANCE_FILTERS.MAILED_DATE}
                    </InputLabel>
                    <KeyboardArrowDownIcon
                      sx={{
                        color: isDatepickerOpen ? "#ED5C2F" : "inherit",
                      }}
                    />
                    <DatePicker
                      minDate={null}
                      onCalendarClose={() => setIsDatepickerOpen(false)}
                      selectsRange={true}
                      onChangeRaw={(e) =>
                        handleRawChangeForDatePicker(e, setDateRange)
                      }
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(update) => {
                        dateProtector(update);
                        setDateRange(update);
                      }}
                      onFocus={() => setIsDatepickerOpen(true)}
                      onBlur={(e) => {
                        if (e.target.value == "") {
                          setDateRange([null, null]);
                        }
                        setIsDatepickerOpen(false);
                      }}
                      placeholderText={`${MESSAGES.CONTACTS.ADVANCE_FILTERS.MAILED_DATE}`}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="mailedDate">
                  <Box className="selectDate">
                    <InputLabel className="dateLabel">
                      {MESSAGES.CONTACTS.ADVANCE_FILTERS.CREATED_DATE}
                    </InputLabel>
                    <KeyboardArrowDownIcon
                      sx={{
                        color: createdDatepickerOpen ? "#ED5C2F" : "inherit",
                      }}
                    />
                    <DatePicker
                      minDate={null}
                      onCalendarClose={() => setCreatedDatepickerOpen(false)}
                      selectsRange={true}
                      startDate={startCreatedDate}
                      endDate={endCreatedDate}
                      onChange={(update) => {
                        dateProtector(update);
                        setCreatedDateRange(update);
                      }}
                      onFocus={() => setCreatedDatepickerOpen(true)}
                      onBlur={() => setCreatedDatepickerOpen(false)}
                      placeholderText={`${MESSAGES.CONTACTS.ADVANCE_FILTERS.CREATED_DATE}`}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="mailedDate">
                  <Box className="selectDate">
                    <InputLabel className="dateLabel">
                      {MESSAGES.CONTACTS.ADVANCE_FILTERS.QR_SCAN_DATE}
                    </InputLabel>
                    <KeyboardArrowDownIcon
                      sx={{
                        color: qrCodeScanDatepickerOpen ? "#ED5C2F" : "inherit",
                      }}
                    />
                    <DatePicker
                      minDate={null}
                      onCalendarClose={() => setQrCodeScanDatepickerOpen(false)}
                      selectsRange={true}
                      startDate={startQrCodeDate}
                      endDate={endQrCodeDate}
                      onChange={(update) => {
                        dateProtector(update);
                        setQrCodeDateRange(update);
                      }}
                      onFocus={() => setQrCodeScanDatepickerOpen(true)}
                      onBlur={() => setQrCodeScanDatepickerOpen(false)}
                      placeholderText={`${MESSAGES.CONTACTS.ADVANCE_FILTERS.QR_SCAN_DATE}`}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box className="filterBtn">
              <Button onClick={() => clearFilters()}>
                {MESSAGES.CONTACTS.ADVANCE_FILTERS.CANCEL_BUTTON}
              </Button>
              <Button onClick={applyFilters}>
                {MESSAGES.CONTACTS.ADVANCE_FILTERS.SUBMIT_BUTTON}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
});

export default AdvanceFilters;
