// Request util for api call
import request from "../../utils/request";

// Actions Types
import {SET_ADMIN_QUERIES_LOADER, SET_ADMIN_QUERIES_DATA, SET_ADMIN_QUERY_DETAILS_DATA, SET_ADMIN_QUERY_DETAILS_LOADER } from "./action-types";

// Utiils
import { dateFormat } from "../../utils/date-format";
import { DEFAULT_PAGINATION } from "../../utils/constants";
import { balanceFormatter } from "../../utils/helperFunctions";
import { success } from "./snackbar-actions";


const fetchAdminQueries = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_ADMIN_QUERIES_LOADER, payload: true });

        let url = 'admin/custom-template-queries';

        let queryPayload = {
            page: payload?.pagination?.page ? payload.pagination.page : DEFAULT_PAGINATION.page,
            pageSize: payload?.pagination?.pageSize ? payload.pagination.pageSize : DEFAULT_PAGINATION.pageSize,
        }

        if (payload?.search?.length) {
            queryPayload.search = payload.search;
        }
        
        const response = await request.get(url, queryPayload);


        if (response.status === 200) {
            const queries = {
                rows: response.data.data,
                count: response.data.data.count,
                totalRecordsInDB: response.data.data.totalRecordsInDB,
                to: response.data.data.to,
                from: response.data.data.from,
                currentPage: response.data.data.currentPage,
                lastPage: response.data.data.lastPage,
                perPage: response.data.data.perPage,
                totalRevenue: response.data.data.totalRevenue,
                totalOrders: response.data.data.total,
            }

            dispatch({ type: SET_ADMIN_QUERIES_DATA, payload: queries });
        }
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_ADMIN_QUERIES_LOADER, payload: false });
    }
}

const fetchAdminQueryDetails = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_ADMIN_QUERY_DETAILS_LOADER, payload: true });

        let url = `admin/custom-template-queries/${payload}`;
        const response = await request.get(url);
        
        if (response.status === 200) {
            dispatch({ type: SET_ADMIN_QUERY_DETAILS_DATA, payload: response.data.data });
        }
    } catch (error) {
        return error;
    } finally {
        dispatch({ type: SET_ADMIN_QUERY_DETAILS_LOADER, payload: false });
    }
}

const updateAdminQueryStatus = (payload) => async () => {
    try {
        const response = await request.post(`sdk/custom-template-queries/${payload.id}/update-query`, payload);
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        return error;
    }
}

const createAdminQuery = (payload) => async (dispatch) => {
    try {
        const response = await request.post(`sdk/custom-template-queries/create`, payload);
        console.log(response, "response after create");
        if (response.status === 200) {
            dispatch(success(response.data.message));
            return response.data.data;
        }
    } catch (error) {
        console.error("Error creating admin query:", error.response?.data || error.message);
        return error;
    }
}

export { fetchAdminQueries, fetchAdminQueryDetails, updateAdminQueryStatus, createAdminQuery };