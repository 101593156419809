import React, { useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import request from "~/utils/request";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";

// Icons
import { CrossModalCIcon } from "~/assets/images";
import ChargeCIcon from "~/assets/images/hire-designer/charge.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const index = (props) => {
  const { openModal, handleCloseModal, designerQueryPayload } = props;

  const [loading, setLoading] = useState(false);

  const userData = useSelector((state) => state.userReducers.user);

  const amount = userData?.organization?.systemConfig?.designerQueryAmount;

  const dispatch = useDispatch();

  const purchase = async () => {
    setLoading(true);
    try {
      const response = await request.post(
        `custom-template-queries/${designerQueryPayload?.id}/purchase/`,
        {}
      );
      if (response.status === 200 && response?.data?.data?.success) {
        handleCloseModal(true);
      } else {
        dispatch(failure(MESSAGES.HIRE_DESIGNER.CHARGE_DIALOG.PURCHASE_FAILED));
        handleCloseModal(false);
      }
    } catch (error) {
      dispatch(failure(MESSAGES.HIRE_DESIGNER.CHARGE_DIALOG.PURCHASE_FAILED));
      handleCloseModal(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BootstrapDialog
      onClose={() => handleCloseModal(false)}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="chargeDialogWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          handleCloseModal(false);
        }}
        className="crossIcon"
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <img src={CrossModalCIcon} alt="close" />
      </IconButton>
      <DialogContent className="chargeDialogContent" dividers>
        <Box className="chargeDialog">
          <img src={ChargeCIcon} alt="charge" />
          <Typography>
            {MESSAGES.HIRE_DESIGNER.CHARGE_DIALOG.Typography_START}{" "}
            <span>${amount}</span>{" "}
            {MESSAGES.HIRE_DESIGNER.CHARGE_DIALOG.Typography_END}
          </Typography>
        </Box>
        <Box className="chargeDialogBtns">
          <Button onClick={purchase} disabled={loading}>
            {loading && (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            )}
            {!loading && MESSAGES.HIRE_DESIGNER.CHARGE_DIALOG.PURCHASE_BUTTON}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default index;
