import React from "react";

//Utils
import { MESSAGES } from "../../../../utils/message";

// MUI Components
import { Box, Container, Typography } from "@mui/material";

// Assets
import NoContact from "../../../../assets/images/contacts/no-contact.svg";

// Styles
import "./styles.scss";

const NoQueriesScreen = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Box className="noContactWrapper">
        <img src={NoContact} alt="no-contacts" />
        <Typography>{MESSAGES.ADMIN_DASHBOARD.QUERIES.NO_TEMPLATE_QUERY}</Typography>
      </Box>
    </Container>
  );
};

export default NoQueriesScreen;
