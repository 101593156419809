import React from "react";

// Utils
import { MESSAGES } from "~/utils/message";

// Components

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";

// Icons
import { CrossModalCIcon } from "~/assets/images";
import PurchaseCIcon from "~/assets/images/hire-designer/purchase.svg";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const index = (props) => {
  const { openModal, handleCloseModal } = props;

  return (
    <>
      <BootstrapDialog
        onClose={handleCloseModal}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="purchaseDialogWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseModal();
          }}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="close" />
        </IconButton>
        <DialogContent className="purchaseDialogContent" dividers>
          <Box className="purchaseDialog">
            <img src={PurchaseCIcon} alt="charge" />
            <Typography variant="h4">
              {MESSAGES.HIRE_DESIGNER.PURCHASE_DIALOG.TITLE}
            </Typography>
            <Typography>
              {MESSAGES.HIRE_DESIGNER.PURCHASE_DIALOG.TYPOGRAPHY}
            </Typography>
          </Box>
          <Box className="purchaseDialogBtns">
            <Button onClick={handleCloseModal}>
              {MESSAGES.HIRE_DESIGNER.PURCHASE_DIALOG.OK_BUTTON}
            </Button>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default index;
