import React, { useEffect } from "react";

//hooks
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//actions
import { fetchOrderOverviewDetails } from "~/redux/actions/orders-actions";

//components
import CustomStepper from "./Stepper";
import TotalCost from "./TotalCost";
import Analytics from "./Analytics";
import DeliveryInfo from "./DeliveryInfo";
import HoldStepper from "./HoldStepper";
import CustomLoader from "./CustomLoader";
import DeliverySkeleton from "./DeliverySkeleton";

// styles
import "./styles.scss";

function Overview() {
  const detail = useSelector((state) => state.ordersReducers.detailOverview);
  const rosCost = useSelector(
    (state) => state.ordersReducers.detailOverview?.stepper?.meta?.rosCost
  );
  const orderCost = useSelector(
    (state) => state.ordersReducers.detailOverview?.stepper?.cost
  );

  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderOverviewDetails({ id }));
  }, []);

  const refreshOverview = () => {
    dispatch(fetchOrderOverviewDetails({ id }));
  };

  return (
    <div className="overwiew_wrapper">
      {detail.stepper.status !== "On Hold" &&
        detail.stepper.status !== "Canceled" && (
          <CustomStepper
            info={detail.stepper}
            refreshOverview={refreshOverview}
          />
        )}
      {(detail.stepper.status == "On Hold" ||
        detail.stepper.status == "Canceled") && (
        <HoldStepper info={detail.stepper} refreshOverview={refreshOverview} />
      )}
      <TotalCost
        info={detail.overview}
        loading={detail.loading}
        rosCost={rosCost}
        orderCost={orderCost}
      />
      {detail.loading ? (
        <CustomLoader />
      ) : (
        <Analytics info={detail.analytics} stepper={detail.stepper} />
      )}
      {detail.loading ? (
        <DeliverySkeleton />
      ) : (
        <DeliveryInfo
          stepper={detail.stepper}
          overview={detail.overview}
          info={detail.analytics}
        />
      )}
    </div>
  );
}

export default Overview;
