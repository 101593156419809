import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import { createViewProof, multiPageTemplates } from "~/utils/template-builder";
import { MESSAGES } from "~/utils/message";
import { ENVELOPE_LEARN_URL } from "~/utils/constants";

// Components
import GenericAutocomplete from "~/components/General/Autocomplete";

// MUI Components
import {
  Box,
  Button,
  Divider,
  Grid,
  InputLabel,
  Typography,
  CircularProgress,
  Input,
} from "@mui/material";

// Icons
import { HtmlIconCIcon } from "~/assets/images";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MailerDetails = (props) => {
  const {
    selectedTemplate,
    templateOptions,
    setSelectedTemplate,
    postageOptions,
    setSelectedPostage,
    selectedPostage,
    evelopeOptions,
    setSelectedEnvelope,
    selectedEnvelope,
    isFlipped,
    setFlipped,
    setSearch,
    setErrors,
    loading,
    errors,
    htmlBox,
    offerPercentageValue,
    setOfferPercentageValue,
  } = props;

  const [downloadingProof, setDownloadingProof] = useState(false);

  const dispatch = useDispatch();

  const downloadViewProof = async () => {
    try {
      setDownloadingProof(true);
      await dispatch(
        createViewProof(selectedTemplate.label, selectedTemplate.id)
      );
      setDownloadingProof(false);
    } catch (error) {
      setDownloadingProof(false);
      dispatch(failure(MESSAGES.VIEW_PROOF_ERROR));
    }
  };

  const handleOfferPercentageChange = (e) => {
    const value = e.target.value;
    const regex = /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/;
    if (!isNaN(value) && regex.test(value)) {
      setOfferPercentageValue(value);
    } else if (value === "" || value < 0 || value === "e") {
      setOfferPercentageValue("");
    }
  };

  return (
    <>
      <Grid item lg={9} md={12} sm={12} xs={12}>
        <Box className="templateSelectWrapper">
          <InputLabel className="selectLabel">
            {MESSAGES.ORDERS.CREATE.TEMPLATE_LABEL}
          </InputLabel>
          <GenericAutocomplete
            placeholder="Select Template"
            options={templateOptions}
            setSelectedValue={setSelectedTemplate}
            selectedValue={selectedTemplate}
            search={setSearch}
            updateErrors={setErrors}
            name={"template"}
            loading={loading}
          />
          {errors.template && (
            <Typography className="errorMessage">
              <sup>*</sup>
              {MESSAGES.ORDERS.CREATE.TEMPLATE_REQUIRED}
            </Typography>
          )}
          {selectedTemplate?.id &&
            !multiPageTemplates.includes(selectedTemplate?.productType) && (
              <Box className="orderInfoWrapper">
                <Box className="orderInfo">
                  <Typography>
                    {MESSAGES.ORDERS.CREATE.PRODUCT_TYPE_LABEL}
                  </Typography>
                  <Typography>{selectedTemplate?.productType}</Typography>
                </Box>
                {selectedTemplate?.envelopeType && (
                  <Box className="orderInfo">
                    <Typography>
                      {MESSAGES.ORDERS.CREATE.ENVELOPE_TYPE_LABEL}
                    </Typography>
                    <Typography>{selectedTemplate?.envelopeType}</Typography>
                  </Box>
                )}
              </Box>
            )}
          {selectedTemplate?.id &&
            multiPageTemplates.includes(selectedTemplate?.productType) && (
              <Box className="orderInfoWrapper">
                <Box className="orderInfo">
                  <Typography>
                    {MESSAGES.ORDERS.CREATE.PRODUCT_TYPE_LABEL}
                  </Typography>
                  <Typography>{selectedTemplate?.productType}</Typography>
                </Box>
                <Box className="orderInfo">
                  <Typography>
                    {MESSAGES.ORDERS.CREATE.POSTCARD_SIZE_LABEL}
                  </Typography>
                  <Typography>{selectedTemplate?.postCardSize}</Typography>
                </Box>
              </Box>
            )}
        </Box>
        {selectedTemplate?.id && (
          <>
            <Divider className="orderDivider dividerWidth" />
            <Box className="templateSelectWrapper">
              <InputLabel className="selectLabel">
                {MESSAGES.ORDERS.CREATE.POSTAGE_TYPE_LABEL}
              </InputLabel>
              <GenericAutocomplete
                placeholder="Postage Type"
                options={postageOptions}
                setSelectedValue={setSelectedPostage}
                disable={selectedTemplate?.productType === "Postcards"}
                selectedValue={selectedPostage}
                search={() => {}}
                updateErrors={setErrors}
                name={"postage"}
              />
              {errors.postage && (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {MESSAGES.ORDERS.CREATE.POSTAGE_TYPE_REQUIRED}
                </Typography>
              )}
            </Box>
          </>
        )}
        {selectedTemplate?.id &&
          !multiPageTemplates.includes(selectedTemplate?.productType) && (
            <Box className="templateSelectWrapper" sx={{ marginTop: "19px" }}>
              <InputLabel className="selectLabel">
                {MESSAGES.ORDERS.CREATE.ENVELOPE_STYLE_LABEL}
                {
                  <>
                    <br />
                    <NavLink to={ENVELOPE_LEARN_URL} target="_blank">
                      <div className="link">
                        {MESSAGES.ORDERS.CREATE.LEARN_TITLE}
                      </div>
                    </NavLink>
                  </>
                }
              </InputLabel>
              <GenericAutocomplete
                placeholder="Envelope Style"
                options={evelopeOptions}
                setSelectedValue={setSelectedEnvelope}
                disable={
                  selectedTemplate?.productType === "Professional Letters"
                }
                selectedValue={selectedEnvelope}
                search={() => {}}
                updateErrors={setErrors}
                name={"envelope"}
              />
              {errors.envelope && (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {MESSAGES.ORDERS.CREATE.ENVELOPE_STYLE_REQUIRED}
                </Typography>
              )}
            </Box>
          )}
          {selectedTemplate?.id && selectedTemplate?.meta?.rosEnabled === true && (
            <Box className="templateSelectWrapper" sx={{ marginTop: "19px" }}>
            <InputLabel className="selectLabel">
              {MESSAGES.ORDERS.CREATE.OFFER_PERCENTAGE_LABEL}{" "}
              <span className="offerPercentageDescription">
                {MESSAGES.ORDERS.CREATE.OFFER_PERCENTAGE_DESCRIPTION}{" "}
                <span className="offerPercentageLink">
                <NavLink to="/property-offers" target="_blank">
                  {MESSAGES.ORDERS.CREATE.OFFER_PERCENTAGE_LINK}
                </NavLink>
                </span>
              </span>
            </InputLabel>
            <Input 
            type="number" 
            placeholder={MESSAGES.ORDERS.CREATE.OFFER_PERCENTAGE_PLACEHOLDER} 
            className="offerPercentageInput" 
            value={offerPercentageValue} 
            onChange={handleOfferPercentageChange}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            />
            {errors.offerPercentage && !offerPercentageValue && (
              <Typography className="errorMessage">
                <sup>*</sup>
                {MESSAGES.ORDERS.CREATE.OFFER_PERCENTAGE_REQUIRED}
              </Typography>
            )}
          </Box>
          )}
      </Grid>
      <Grid
        item
        lg={3}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "end" }}
      >
        {selectedTemplate?.id &&
          selectedTemplate?.frontThumbnailUrl &&
          selectedTemplate?.templateType === "json" && (
            <Box className="postCardProof">
              <Box className={`flip-container ${isFlipped ? "flip" : ""}`}>
                <Box className="flipper">
                  <img
                    src={
                      !isFlipped
                        ? selectedTemplate.frontThumbnailUrl
                        : selectedTemplate.backThumbnailUrl
                    }
                    alt={selectedTemplate?.productType}
                  />
                </Box>
              </Box>
              <Box className="viewProofBtnsWrapper">
                {multiPageTemplates.includes(selectedTemplate?.productType) && (
                  <Box className="proofBtns">
                    <ArrowBackIosNewIcon
                      onClick={() => setFlipped((prev) => !prev)}
                    />
                    <ArrowForwardIosIcon
                      onClick={() => setFlipped((prev) => !prev)}
                    />
                  </Box>
                )}
                <Button className="viewProofBtn" onClick={downloadViewProof}>
                  {downloadingProof ? (
                    <CircularProgress
                      sx={{
                        color: "#ed5c2f",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : (
                    MESSAGES.ORDERS.CREATE.VIEW_PROOF_LABEL
                  )}
                </Button>
              </Box>
            </Box>
          )}
        {selectedTemplate?.id && selectedTemplate.templateType === "html" && (
          <Box
            className={
              htmlBox ? "orderHTMLBox orderHTMLBoxWidth" : "orderHTMLBox"
            }
          >
            <img src={HtmlIconCIcon} alt="html-icon" />
            <Button onClick={downloadViewProof}>
              {downloadingProof ? (
                <CircularProgress
                  sx={{
                    color: "#ed5c2f",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                />
              ) : (
                MESSAGES.ORDERS.CREATE.VIEW_PROOF_LABEL
              )}
            </Button>
            <Typography>
              {MESSAGES.ORDERS.CREATE.HTML_TEMPLATE_LABEL}
            </Typography>
          </Box>
        )}
      </Grid>
    </>
  );
};

export default MailerDetails;
