import React from "react";

//utils
import { MESSAGES } from "~/utils/message";
import { balanceFormatter, numberWithComma } from "~/utils/helperFunctions";

// Components
import Loader from "~/components/General/Loader";

// MUI Componentss
import { Box } from "@mui/material";

// Icons
import {
  TotalContactCIcon,
  DeliverableCIcon,
  UndeliverableCIcon,
} from "~/assets/images";

// styles
import "./styles.scss";

function TotalCost({ info, loading, rosCost, orderCost }) {
  return (
    <Box className="total_cost">
      <div className="card_outer">
        <div className="card">
          <img src={TotalContactCIcon} alt="icon" />
          <div>
            <span className="meta_info">
              {MESSAGES.ORDERS.DETAIL.OVERVIEW.TOTAL_CONTACTS}
            </span>
            <p className="cost">{info.totalContacts}</p>
          </div>
        </div>
        <div className="card">
          <img src={DeliverableCIcon} alt="icon" />
          <div>
            <span className="meta_info">
              {MESSAGES.ORDERS.DETAIL.OVERVIEW.DELIVERABLES}
            </span>
            <p className="cost">{info.deliverableContacts}</p>
          </div>
        </div>
        <div className="card">
          <img src={UndeliverableCIcon} alt="icon" />
          <div>
            <span className="meta_info">
              {MESSAGES.ORDERS.DETAIL.OVERVIEW.UNDELIVERABLES}
            </span>
            <p className="cost">{info.unDeliverableContacts}</p>
          </div>
        </div>
      </div>
      <div className="total_card">
        {loading ? (
          <div className="cost_loader">
            <Loader />
          </div>
        ) : (
          <>
            <span className="name">
              {MESSAGES.ORDERS.DETAIL.OVERVIEW.TOTAL_COST}
            </span>
            <p className="ammount">{balanceFormatter(orderCost)}</p>
            <p className="info_txt">
              {numberWithComma(info.deliverableContacts)}&nbsp;
              <span className="txt">deliverable contacts&nbsp;</span>
              <span className="highlighted">x</span>&nbsp;
              {info?.costGsv || rosCost ? <span>(</span> : <></>}
              {balanceFormatter(info.perMailerCost)}
              <span className="txt">&nbsp;per mailer</span>
              {info?.costGsv ? (
                <>
                  &nbsp;<span className="highlighted">+</span>&nbsp;
                  {balanceFormatter(info.perMailerGsvCost)}
                  <span className="txt">&nbsp;GSV cost</span>
                  {!rosCost ? <span>)</span> : <></>}
                </>
              ) : null}
              {info?.costRos ? (
                <>
                  &nbsp;<span className="highlighted">+</span>&nbsp;
                  {balanceFormatter(info?.perMailerRosCost)}
                  <span className="txt">&nbsp;ROS cost</span>)
                </>
              ) : null}
            </p>
          </>
        )}
      </div>
    </Box>
  );
}

export default TotalCost;
