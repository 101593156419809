import React, { useEffect, useState } from "react";

import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Radio,
  TextField,
} from "@mui/material";

// MESSAGES
import { MESSAGES } from "~/utils/message";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const OffersModal = ({ addresses = [] }) => {
  const [customValue, setCustomValue] = useState(100);

  const handleOfferPercentageChange = (e) => {
    const value = e.target.value;
    const regex = /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/;
    if (!isNaN(value) && regex.test(value)) {
      setCustomValue(value);
    } else if (value === "" || value < 0) {
      setCustomValue("");
    }
  };

  useEffect(() => {
    setCustomValue(100);
  }, [addresses]);

  return (
    <Box className="offerPercentageWrapper">
      <TableContainer>
        <Table className="offerTable">
          <TableHead>
            <TableRow>
              <TableCell>{MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.OFFER_LEVEL}</TableCell>
              <TableCell>{MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.OFFER_1}</TableCell>
              <TableCell>{MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.OFFER_2}</TableCell>
              <TableCell>{MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.OFFER_3}</TableCell>
              <TableCell>{MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.OFFER_4}</TableCell>
              <TableCell>
                {MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.CUSTOM}
                <Box className="customPercentageWrapper">
                  <TextField
                    variant="outlined"
                    type="number"
                    className="customPercentageInput"
                    value={customValue}
                    onChange={handleOfferPercentageChange}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                  <span>%</span>
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="offerTableBody">
            {addresses?.slice(0, MESSAGES.ORDERS.CREATE.MAX_RECORDS)?.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.address}</TableCell>
                <TableCell>
                  {row?.estimate
                    ? formatter.format((row?.estimate * 0.5).toFixed(2))
                    : MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.NO_DATA_FOUND}
                </TableCell>
                <TableCell>
                  {row?.estimate
                    ? formatter.format((row?.estimate * 0.79).toFixed(2))
                    : MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.NO_DATA_FOUND}
                </TableCell>
                <TableCell>
                  {row?.estimate
                    ? formatter.format((row?.estimate * 0.83).toFixed(2))
                    : MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.NO_DATA_FOUND}
                </TableCell>
                <TableCell>
                  {row?.estimate
                    ? formatter.format((row?.estimate * 0.9).toFixed(2))
                    : MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.NO_DATA_FOUND}
                </TableCell>
                <TableCell>
                  {row?.estimate
                    ? formatter.format(
                        (row?.estimate * (customValue / 100)).toFixed(2)
                      )
                    : MESSAGES.CONTACTS.IMPORT.OFFER_PERCENTAGE_TABLE.NO_DATA_FOUND}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OffersModal;
