import React, { useState, useEffect, useRef } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Actions
import { failure, success } from "~/redux/actions/snackbar-actions";
import {
  fetchContacts,
  fetchContactLabels,
  contactBulkActions,
} from "~/redux/actions/contacts-actions";
import { fetchCustomFields } from "~/redux/actions/customFields-actions";
import { CLEAR_CONTACTS_FILTERS } from "~/redux/actions/action-types";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION } from "~/utils/constants";

// Components
import Table from "./Table";
import ExportModal from "./ExportModal";
import DeleteModal from "./DeleteModal";
import AdvanceFilters from "./AdvanceFilters";
import NoContactsScreen from "./NoContactsScreen/index";
import TagsUpdate from "./TagsUpdate";

// MUI Components
import {
  Container,
  Box,
  Grid,
  Button,
  Input,
  InputAdornment,
  IconButton,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";

// Icons
import { ActiveFilterCIcon, InActiveFilterCIcon, ImportCIcon, ContactSearchCIcon, ArrowDownContactsCIcon, AddCIcon, AddDarkCIcon, RemoveTagsCIcon, ExportCIcon, DeleteWebhookCIcon } from "~/assets/images";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Styles
import "./styles.scss";

const Contacts = () => {
  const [mounted, setIsMounted] = useState(false);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchApplied, setSearchApplied] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [tagsUpdateModal, setTagsUpdateModal] = useState({
    open: false,
    type: "",
  });
  const [selectedTags, setSelectedTags] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAdvanceFilters, setOpenAdvanceFilters] = useState(false);

  // Actions State
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const actionsOpen = Boolean(actionsAnchorEl);
  const handleActionsClick = (event) => {
    setActionsAnchorEl(event.currentTarget);
  };
  const handleActionsClose = () => {
    setActionsAnchorEl(null);
  };
  // Actions State
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const childRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalContactsInDB = useSelector(
    (state) => state.contactsReducers.totalContactsInDB
  );
  const applyFilters = useSelector(
    (state) => state.contactsReducers.applyFilters
  );
  const filters = useSelector((state) => state.contactsReducers.filters);
  const filterSize = useSelector((state) => state.contactsReducers.filterSize);

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  // Use the useMediaQuery hook to check the screen size
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  // Determine the maxWidth based on the screen size
  const maxWidth = isXlScreen ? "xxl" : "xl";

  const handleSearch = () => {
    if (search) {
      setPagination({ ...pagination, page: 0 });
      setSearchApplied(true);
      getContacts();
    }
  };

  const searchKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const getContacts = () => {
    let payload = {
      pagination,
      search: search.length ? search : null,
      applyFilters: applyFilters,
      filters: applyFilters ? filters : null,
    };
    dispatch(fetchContacts(payload));
  };

  const exportContacts = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "export",
        search: search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await contactBulkActions(payload);
      getContacts();
      setSelectedRows([]);
      handleActionsClose();
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.data.message));
    } finally {
      setLoading(false);
      setExportModal(false);
    }
  };

  const deleteContacts = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "delete",
        search: search.length ? search : null,
        applyFilters: applyFilters,
        filters: applyFilters ? filters : null,
      };
      const response = await contactBulkActions(payload);
      getContacts();
      setSelectedRows([]);
      handleActionsClose();
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error.response.data.message));
    } finally {
      setLoading(false);
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    setIsMounted(true);
    getContacts();
    dispatch(fetchContactLabels());
    return () => {
      dispatch({ type: CLEAR_CONTACTS_FILTERS });
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      getContacts();
    }
  }, [pagination.page, pagination.pageSize, filterSize, filters]);

  useEffect(() => {
    if (!search && mounted) {
      setSearchApplied(false);
      setSearch("");
      getContacts();
    }
  }, [search]);

  useEffect(() => {
    if (mounted) {
      let payload = {
        DEFAULT_PAGINATION,
        search: search.length ? search : null,
      };
      dispatch(fetchContacts(payload));
      dispatch(fetchContactLabels());
      dispatch(fetchCustomFields());
    }
  }, [currentMode]);

  const noContactsInDb = () => {
    return (
      <Container
        maxWidth={maxWidth}
        sx={{
          margin: "120px auto 50px",
        }}
      >
        <NoContactsScreen />
      </Container>
    );
  };

  if (totalContactsInDB < 1 && totalContactsInDB !== null) {
    return noContactsInDb();
  }

  return (
    <Container
      maxWidth={maxWidth}
      sx={{
        margin: "120px auto 50px",
      }}
    >
      <Grid container rowSpacing={3}>
        <Grid item lg={6} md={12} sm={12} xs={12} className="searchBtnWrapper">
          <Box className="searchWrapper">
            <Input
              className={`searchInput ${searchApplied ? "searchApplied" : ""}`}
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value.trimStart())}
              onKeyDown={searchKeyDown}
              placeholder={MESSAGES.CONTACTS.SEARCH_PLACE_HOLDER}
              endAdornment={
                <InputAdornment position="end" className="searchInputAdornment">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    className="searchIcon"
                    onClick={handleSearch}
                  >
                    <img src={ContactSearchCIcon} alt="search" />
                  </IconButton>
                  {searchApplied && (
                    <HighlightOffIcon
                      onClick={() => {
                        setSearch("");
                      }}
                      className="clearSerach"
                    />
                  )}
                </InputAdornment>
              }
            />
            <Box className="filterBtnWrapper">
              <img
                onClick={() => {
                  setOpenAdvanceFilters(true);
                }}
                className={
                  filterSize > 0
                    ? "filterImage activeFilter"
                    : "filterImage noFilter"
                }
                src={filterSize > 0 ? ActiveFilterCIcon : InActiveFilterCIcon}
                alt="Filter"
              />
              {filterSize > 0 && (
                <Typography className="filtersCounter">{filterSize}</Typography>
              )}
            </Box>
            {filterSize > 0 && (
              <Button
                onClick={() => {
                  childRef.current.clearFilters();
                }}
                className="clearFilterBtn"
              >
                {MESSAGES.CONTACTS.CLEAR_FILTER_BTN_TEXT}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          sx={{
            marginTop: { md: "0px", sm: "20px", xs: "20px" },
            alignItems: "flex-start",
          }}
        >
          <Box className="contactActionsBtnWrapper">
            {/* Actions Start */}
            {selectedRows.length > 0 && 
            <>
            <Button
              id="basic-button"
              aria-controls={actionsOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={actionsOpen ? "true" : undefined}
              onClick={handleActionsClick}
              sx={{
                maxWidth: "100px !important",
                gap: "0 !important",
              }}
            >
              <Typography className="btnTxt">
                {MESSAGES.CONTACTS.ACTIONS_BTN_TEXT}
              </Typography>
              <KeyboardArrowDownIcon sx={{color: "#000"}}/>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={actionsAnchorEl}
              open={actionsOpen}
              onClose={handleActionsClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className="menuItem"
                onClick={() => {
                  setExportModal(true);
                  handleActionsClose();
                }}
              >
                <img src={ExportCIcon} alt="icon" />
                {MESSAGES.CONTACTS.ACTIONS.EXPORT_CONTACTS}
              </MenuItem>
              <MenuItem
                className="menuItem"
                onClick={() => {
                  setDeleteModal(true);
                  handleActionsClose();
                }}
              >
                <img src={DeleteWebhookCIcon} alt="icon" />
                {MESSAGES.CONTACTS.ACTIONS.DELETE_CONTACTS}
              </MenuItem>
              <MenuItem
                className="menuItem"
                onClick={() => {
                  setTagsUpdateModal({
                    open: true,
                    type: "update",
                  });
                  handleActionsClose();
                }}
              >
                <img src={AddDarkCIcon} alt="icon" />
                {MESSAGES.CONTACTS.ACTIONS.ADD_TAGS}
              </MenuItem>
              <MenuItem
                className="menuItem"
                onClick={() => {
                  setTagsUpdateModal({
                    open: true,
                    type: "remove",
                  });
                  handleActionsClose();
                }}
              >
                <img src={RemoveTagsCIcon} alt="icon" style={{width: "19px", height: "19px"}}/>
                {MESSAGES.CONTACTS.ACTIONS.REMOVE_TAGS}
              </MenuItem>
            </Menu></>}
            {/* Actions End */}
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <img src={AddCIcon} alt="icon" />
              <Typography className="btnTxt">
                {MESSAGES.CONTACTS.CREATE_BTN_TEXT}
              </Typography>
              <img src={ArrowDownContactsCIcon} alt="icon" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                className="menuItem"
                onClick={() => {
                  navigate("/contacts/create");
                }}
              >
                <img src={AddDarkCIcon} alt="icon" />
                Create Contact
              </MenuItem>
              <MenuItem
                className="menuItem"
                onClick={() => {
                  navigate("/contacts/import");
                }}
              >
                <img src={ImportCIcon} alt="icon" />
                Import Contacts
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
      <Table
        pagination={pagination}
        updatePagination={setPagination}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        searchApplied={searchApplied}
      />
      <TagsUpdate
        open={tagsUpdateModal.open}
        type={tagsUpdateModal.type}
        handleClose={() => {
          setTagsUpdateModal({
            open: false,
            type: "",
          });
          setSelectedTags([]);
        }}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        header={tagsUpdateModal.type === "update" ? MESSAGES.CONTACTS.TAGS_UPDATE_TEXT : tagsUpdateModal.type === "remove" ? MESSAGES.CONTACTS.TAGS_REMOVE_TEXT : ""}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
      <ExportModal
        open={exportModal}
        handleExport={exportContacts}
        handleClose={() => {
          setExportModal(false);
        }}
        loading={loading}
        header={
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.CONTACTS.MULTI_EXPORT_HEADER
            : MESSAGES.CONTACTS.SINGLE_EXPORT_HEADER
        }
        message={
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.CONTACTS.EXPORT_CONTACTS
            : MESSAGES.CONTACTS.EXPORT_SINGLE_CONTACTS
        }
        subject={MESSAGES.CONTACTS.EXPORT_MESSAGE}
      />
      <DeleteModal
        open={deleteModal}
        handleExport={deleteContacts}
        handleClose={() => {
          setDeleteModal(false);
        }}
        loading={loading}
        header={
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.CONTACTS.MULTI_DELETE_HEADER
            : MESSAGES.CONTACTS.SINGLE_DELETE_HEADER
        }
        message={
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.CONTACTS.DELETE_CONTACTS
            : MESSAGES.CONTACTS.DELETE_SINGLE_CONTACTS
        }
        subject={""}
      />
      <AdvanceFilters
        open={openAdvanceFilters}
        handleCloseFilter={() => {
          setOpenAdvanceFilters(false);
        }}
        updatePagination={setPagination}
        ref={childRef}
      />
    </Container>
  );
};

export default Contacts;
