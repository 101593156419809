import React, { useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import {
  getAllTransactions,
  transBulkActions,
} from "~/redux/actions/stripe-actions";
import { failure, success } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// Components
import BalanceCard from "./BalanceCard";
import PaymentCard from "./PaymentCard";
import TransactionListing from "./TransactionListing";
import ExportModal from "~/components/Contacts/ExportModal";

// MUI Components
import { Container, Typography, Box, Grid, Button } from "@mui/material";

// Icons
import { ExportCIcon } from "~/assets/images";

// styles
import "./styles.scss";

const Wallet = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const exportUsers = async () => {
    try {
      setLoading(true);
      const payload = {
        ids: selectedRows[0] === -1 ? -1 : selectedRows,
        action: "export",
      };
      const response = await transBulkActions(payload);
      dispatch(getAllTransactions(1, 10));
      setSelectedRows([]);
      dispatch(success(response.data.message));
    } catch (error) {
      dispatch(failure(error?.response?.data?.message));
    } finally {
      setLoading(false);
      setExportModal(false);
    }
  };

  return (
    <Box className="walletMain">
      <Container maxWidth="xxl">
        <Box className="walletWrapper">
          <Grid container>
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <Typography className="walletTitle">
                {MESSAGES.ACCOUNT.WALLET.TITLE}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <BalanceCard />
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12}>
              <div className="paymentCard">
                <PaymentCard />
              </div>
            </Grid>
            {selectedRows.length > 0 && (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="walletExportBtn"
              >
                <Button
                  onClick={() => {
                    setExportModal(true);
                  }}
                >
                  <img src={ExportCIcon} alt="actions" />
                  {MESSAGES.ACCOUNT.WALLET.EXPORT.EXPORT_BTN_TEXT}
                </Button>
              </Grid>
            )}
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className="tableOuter">
                <TransactionListing
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ExportModal
        open={exportModal}
        handleExport={exportUsers}
        handleClose={() => {
          setExportModal(false);
        }}
        loading={loading}
        header={
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.ACCOUNT.WALLET.EXPORT.MULTI_EXPORT_HEADER
            : MESSAGES.ACCOUNT.WALLET.EXPORT.SINGLE_EXPORT_HEADER
        }
        message={
          selectedRows[0] === -1 || selectedRows.length > 1
            ? MESSAGES.ACCOUNT.WALLET.EXPORT.EXPORT_TRANS
            : MESSAGES.ACCOUNT.WALLET.EXPORT.EXPORT_SINGLE_TRANS
        }
        subject={MESSAGES.ACCOUNT.WALLET.EXPORT.EXPORT_MESSAGE}
      />
    </Box>
  );
};

export default Wallet;
