import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import useScreenWidth from "~/hooks/useScreenWidth";

// Actions
import { success } from "~/redux/actions/snackbar-actions";
import { publishTemplateQuery } from "~/redux/actions/admin-custom-designer-query.js";
import {
  fetchAdminQueries,
  fetchAdminQueryDetails,
} from "~/redux/actions/admin-query-actions.js";

// Utils
import { DEFAULT_PAGE_SIZES, DEFAULT_PAGINATION } from "~/utils/constants.js";
import { dateFormat } from "~/utils/date-format.js";
import { stringLength } from "~/utils/helperFunctions.js";
import { displayTooltip } from "~/hooks/displayTooltip.js";
import { MESSAGES } from "~/utils/message";

// Components
import ConfirmDialog from "~/components/Orders/ConfirmDialog/ConfirmDialog.jsx";
import QueryDetails from "../QueryDetails";
import Loader from "~/components/General/Loader";

// Mui Components
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Publish from "~/assets/images/admin/stack.svg";
import Start from "~/assets/images/admin/play.svg";
import Details from "~/assets/images/admin/eye.svg";
import Edit from "~/assets/images/webhooks/edit.svg";
import Edit4CIcon from "~/assets/images/accounts/editnew.png";
import { InfoWebhookCIcon } from "~/assets/images/index";

// Styles
import "./styles.scss";

const QueriesListing = (props) => {
  const { queries, updatePagination, pagination } = props;
  const isTooltip = displayTooltip();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [activeRowId, setActiveRowId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    loading: false,
    currentAction: null,
    params: null,
  });
  const openMenu = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageSizes = DEFAULT_PAGE_SIZES;
  const queryDetails = useSelector(
    (state) => state.adminQueriesReducers.queryDetails
  );

  const queryDetailsLoader = useSelector(
    (state) => state.adminQueriesReducers.queryDetailsLoader
  );
  const rowsCount = useSelector(
    (state) => state.adminQueriesReducers.rowsCount
  );
  const to = useSelector((state) => state.adminQueriesReducers.to);
  const from = useSelector((state) => state.adminQueriesReducers.from);
  const lastPage = useSelector(
    (state) => state.adminQueriesReducers.lastPage || 1
  );
  const currentPage = useSelector(
    (state) => state.adminQueriesReducers.currentPage || 1
  );
  const perPage = useSelector(
    (state) => state.adminQueriesReducers.perPage || DEFAULT_PAGINATION.pageSize
  );
  const isLoading = useSelector((state) => state.adminQueriesReducers.loading);

  const handleStart = (id) => {
    navigate(`/admin/designer-queries/template/${id}`);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onConfirmPublish = async () => {
    setDialog({
      ...dialog,
      loading: true,
    });
    await publishTemplateQuery(selectedRow);
    setDialog({
      open: false,
      message: "",
      loading: false,
      params: null,
      currentAction: null,
    });
    setSelectedRow(null);
    dispatch(success(MESSAGES.ADMIN_DASHBOARD.QUERIES.TEMPLATE_PUBLISHED));
    dispatch(fetchAdminQueries());
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openQueryDetails = (params) => {
    setOpen(true);
    dispatch(fetchAdminQueryDetails(params.id));
  };

  const closeQueryDetails = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    updatePagination({
      page: newPage,
      pageSize: pagination.pageSize,
    });
  };

  const useScreen = useScreenWidth();

  const PublishedTemplateIdTooltip = (params) => {
    return (
      <>
        <span className="templateNameTooltip">{MESSAGES.ADMIN_DASHBOARD.QUERIES.PUBLISHED_TEMPLATE_ID}</span> {params.row.templateId}
      </>
    )
  };

  const columns = [
    {
      field: "templateName",
      headerName: "Template Name",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.meta.templateTitle}`, 17) ? (
          <Box className="tempInfoWrapper">
          <Tooltip
            title={params.row.meta.templateTitle}
            disableHoverListener={isTooltip}
          >
            <Box className="tempValue">
              <Typography>{params.row.meta.templateTitle}</Typography>
              <Typography>ID: {params.row.id}</Typography>
            </Box>
          </Tooltip>
          {params.row.status === "Published" && (
              <Tooltip
                title={PublishedTemplateIdTooltip(params)}
                disableHoverListener={isTooltip}
              >
                <img src={InfoWebhookCIcon} alt="info" width={15} height={15} />
              </Tooltip>
            )}
          </Box>
        ) : (
          <Box className="tempInfoWrapper">
            <Box className="tempValue">
              <Typography>{params.row.meta.templateTitle}</Typography>
              <Typography>ID: {params.row.id}</Typography>
            </Box>
            {params.row.status === "Published" && (
              <Tooltip
                title={PublishedTemplateIdTooltip(params)}
                disableHoverListener={isTooltip}
              >
                <img src={InfoWebhookCIcon} alt="info" width={15} height={15} />
              </Tooltip>
            )}
          </Box>
        ),
    },
    {
      field: "productType",
      headerName: "Product Type",
      type: "number",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) =>
          <Box className="productValueWrapper">
            <Typography>{params.row.product.productType || "-"}</Typography>
            {params.row.product.productType === "Postcards" && (
            <Typography>{params.row.product.paperSize}</Typography>
          )}
          </Box>
    },
    {
      field: "email",
      headerName: "Email",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) =>
        stringLength(`${params.row.email}`, 21) ? (
          <Tooltip title={params.row.email} disableHoverListener={isTooltip}>
            <Box className="productValueWrapper">
              <Typography>{params.row.email || "-"}</Typography>
            </Box>
          </Tooltip>
        ) : (
          <Box className="productValueWrapper">
            <Typography>{params.row.email || "-"}</Typography>
          </Box>
        ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.status}</Typography>
        </Box>
      ),
    },
    {
      field: "source",
      headerName: "Source",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{params.row.source}</Typography>
        </Box>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{dateFormat(params.row.createdAt)}</Typography>
        </Box>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) => (
        <Box className="addressWrapper">
          <Typography>{dateFormat(params.row.updatedAt)}</Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 205,
      sortable: false,
      flex: window.innerWidth > 1500 ? 1 : 0,
      renderCell: (params) => (
        <>
          {useScreen ? (
            <Box className="actionsWrapper">
              {params?.row?.status === "In progress" ? (
                <Tooltip title="Continue Editing">
                  <img
                    src={Edit4CIcon}
                    alt="edit"
                    width={20}
                    height={20}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleStart(params?.row?.id);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    params?.row?.status === "Published"
                      ? "Already Published"
                      : "Start"
                  }
                >
                  <img
                    src={Start}
                    alt="start"
                    width={20}
                    height={20}
                    style={{
                      opacity: params.row?.status === "Published" ? 0.5 : 1,
                      cursor:
                        params.row?.status === "Published"
                          ? "not-allowed"
                          : "pointer",
                    }}
                    onClick={
                      params?.row?.status === "Published"
                        ? null
                        : (event) => {
                            event.stopPropagation();
                            handleStart(params?.row?.id);
                          }
                    }
                  />
                </Tooltip>
              )}
              <Tooltip title="View Details">
                <img
                  src={Details}
                  alt="details"
                  width={23}
                  height={23}
                  onClick={() => openQueryDetails(params?.row)}
                />
              </Tooltip>
              <Tooltip
                title={
                  ["Published", "Pending"].includes(params.row.status)
                    ? params.row.status === "Pending"
                      ? "Cannot Publish Now"
                      : "Already Published"
                    : "Publish"
                }
              >
                <img
                  src={Publish}
                  alt="publish"
                  width={20}
                  height={20}
                  onClick={
                    ["Published", "Pending"].includes(params.row.status)
                      ? null
                      : (event) => {
                          event.stopPropagation();
                          setSelectedRow(params.row);
                          setDialog({
                            open: true,
                            message:
                              "Are you sure you want to publish this template?",
                            loading: false,
                            currentAction: "publish",
                            params: params,
                            successButtonName: "Yes",
                            heading: "Publish Template",
                          });
                        }
                  }
                  style={{
                    opacity: ["Published", "Pending"].includes(
                      params.row.status
                    )
                      ? 0.5
                      : 1,
                    cursor: ["Published", "Pending"].includes(params.row.status)
                      ? "not-allowed"
                      : "pointer",
                  }}
                />
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={(event) => {
                  event.stopPropagation();
                  setActiveRowId(params.row); // Set active row ID when the menu is opened
                  handleClick(event);
                }}
              >
                <MoreVertIcon
                  sx={{
                    color:
                      activeRowId?.id === params.row.id && anchorEl
                        ? "#ed5c2f"
                        : "inherit",
                  }}
                />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{ "aria-labelledby": "long-button" }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxWidth: 140,
                  },
                  className: "tempActionsDropdown",
                }}
              >
                {activeRowId?.status === "In progress" && (
                  <MenuItem onClick={() => handleStart(activeRowId?.id)}>
                    <img src={Edit4CIcon} alt="edit" />
                    Continue Editing
                  </MenuItem>
                )}
                {activeRowId?.status === "Published" ||
                activeRowId?.status === "In progress" ? (
                  <></>
                ) : (
                  <MenuItem onClick={() => handleStart(activeRowId?.id)}>
                    <img src={Start} alt="search" />
                    Start
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(event) => {
                    event.stopPropagation();
                    openQueryDetails(activeRowId);
                    handleClose();
                  }}
                >
                  <img src={Details} alt="duplicate" />
                  View Details
                </MenuItem>
                {activeRowId?.status === "Published" ||
                activeRowId?.status === "Pending" ? (
                  <></>
                ) : (
                  <MenuItem
                    onClick={() => {
                      setSelectedRow(activeRowId);
                      handleClose();
                      setDialog({
                        open: true,
                        message:
                          "Are you sure you want to publish this template?",
                        loading: false,
                        currentAction: "publish",
                        params: activeRowId,
                        successButtonName: "Yes",
                        heading: "Publish Template",
                      });
                    }}
                  >
                    <img src={Publish} alt="Delete" />
                    Publish
                  </MenuItem>
                )}
              </Menu>
            </Box>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  return (
    <>
      <div className="queryListingWrapper">
        <DataGrid
          rows={queries}
          columns={columns}
          pageSizeOptions={[10, 20, 50, 100]}
          rowCount={rowsCount}
          pagination={pagination}
          paginationMode="server"
          hideFooterSelectedRowCount
          hideFooterPagination
          rowSelection={false}
          getRowId={(row) => row.id}
          disableColumnMenu={true}
          className="designerQueryTable"
          loading={isLoading}
          components={{
            NoRowsOverlay: () => (
              <Stack className="noRowsText">
                No results with current filters.
              </Stack>
            ),
            loadingOverlay: Loader,
          }}
        />
        <Box className="paginationWrapper">
          <Pagination
            count={lastPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChangePage}
            page={currentPage}
            renderItem={(item) => (
              <PaginationItem
                slots={{
                  previous: ArrowBackIosNewIcon,
                  next: ArrowForwardIosIcon,
                }}
                {...item}
              />
            )}
          />
          <Select
            className={
              perPage >= 100 ? `pageSelect pageSelectChange` : `pageSelect`
            }
            value={perPage}
            IconComponent={() => (
              <KeyboardArrowDownIcon
                sx={{ position: "absolute", right: "5px", zIndex: 1 }}
              />
            )}
          >
            {pageSizes.map((pageSize) => {
              return (
                <MenuItem
                  key={pageSize}
                  value={pageSize}
                  onClick={() => {
                    updatePagination({
                      page: 0,
                      pageSize: pageSize,
                    });
                  }}
                >
                  {pageSize}
                </MenuItem>
              );
            })}
          </Select>
          <Typography>
            Showing {rowsCount ? from : 0} to {rowsCount < to ? rowsCount : to}{" "}
            of {rowsCount} results
          </Typography>
        </Box>
      </div>
      <QueryDetails
        open={open}
        onClose={closeQueryDetails}
        data={queryDetails}
        loading={queryDetailsLoader}
      />
      {dialog.open && (
        <ConfirmDialog
          dialog={dialog}
          open={dialog.open}
          loading={dialog.loading}
          handleClose={() => {
            setDialog({
              open: false,
              message: "",
              loading: false,
              params: null,
              currentAction: null,
            });
            setSelectedRow(null);
          }}
          handleOk={onConfirmPublish}
        />
      )}
    </>
  );
};

export default QueriesListing;
