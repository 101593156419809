import React, { useEffect, useState } from "react";

// Third Party Libraries
import moment from 'moment'

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions 
import { addFunds } from "~/redux/actions/stripe-actions.js";
import { success, failure } from "~/redux/actions/snackbar-actions.js";
import { payAndProcess } from "~/redux/actions/orders-actions.js";
import { getUserData } from "~/redux/actions/auth-actions.js";

// Utils
import { MIN_FUNDING_LIMIT } from "~/utils/constants.js";
import { MESSAGES } from "~/utils/message.js";
import { numberWithComma } from "~/utils/helperFunctions.js";
import { dateFormat } from "~/utils/date-format.js";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Input,
  InputLabel,
  Tooltip,
  CircularProgress,
  Divider,
} from "@mui/material";

// Icons
import { InfoWalletCIcon, ModalCrossCIcon } from "~/assets/images";
import DoneIcon from "@mui/icons-material/Done";

// Styles
import "./styles.scss";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddFunds = (props) => {
  const { handleClose, open, params, refreshOverview } = props;
  const user = useSelector((state) => state.userReducers?.user);
  const [fund, setFund] = useState('');
  const [autoFunds, setAutoFunds] = useState(50);
  const [isAutoFundingEnabled, setIsAutoFundingEnabled] = useState(user.organization.isAutoFundingEnabled);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [autoFundError, setAutoFundError] = useState("");
  const [respError, setRespError] = useState('');
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);

  const validateFunding = (value, setErrorCallback) => {
    if (isNaN(value) || value < MIN_FUNDING_LIMIT) {
      setErrorCallback(MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_FUNDING_VALIDATION);
      return false;
    } else {
      setErrorCallback("");
      return true;
    }
  };


  const handleAddFunding = async () => {
    const isFundValid = validateFunding(parseFloat(fund.toString().replaceAll(",", "")), setError);
    const isAutoFundValid = validateFunding(parseFloat(autoFunds.toString().replaceAll(",", "")), setAutoFundError);

    if (!isFundValid || !isAutoFundValid) return;

    if(fund < params.row.cost) {
      setError(MESSAGES.ORDERS.DETAIL.OVERVIEW.AMOUNT_NOT_ENOUGH_FOR_ORDER)
      return
    }

    setLoading((prev) => !prev);
    const data = await addFunds(parseFloat(fund.toString().replaceAll(",", "")), isAutoFundingEnabled, parseFloat(autoFunds.toString().replaceAll(",", "")));
    if (data.status === 200) {
      handleClose();
      const response = await dispatch(payAndProcess({orderId: params.row.id}));
      if (response.status === 200) {
        dispatch(getUserData());
        dispatch(success(response.data.message));
        refreshOverview();
      }else {
        dispatch(failure(response.data.message));
      }
    } else {
      setRespError(data.data.message);
    }
    setLoading((prev) => !prev);

  }

  const handleChangeInput = (value, tab = false, isAutoFundAmount = false) => {
    if (typeof value == "string") {
      let valueToAsign = value.replaceAll("$", "").replaceAll(",", "");
      if (valueToAsign !== "" && !/^-?\d*\.?\d{0,2}$/.test(valueToAsign))
        return;
      valueToAsign = numberWithComma(valueToAsign);
      isAutoFundAmount ? setAutoFunds(valueToAsign) : setFund(valueToAsign);
    } else {
      value = numberWithComma(value);
      isAutoFundAmount ? setAutoFunds(value) : setFund(value);
    }
    if (error && value >= 50) {
      setError("");
    }
    if (autoFundError && value >= 50) {
      setAutoFundError("");
    }
    tab && setActiveTab(tab);
  };

  const currentDate = moment();

  useEffect(()=>{
    user?.organization?.minAutoFundingAmount > 50 &&
    setAutoFunds(user?.organization?.minAutoFundingAmount);
  },[])

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="ordersfundsModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            padding: "0",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ModalCrossCIcon />
        </IconButton>
        <DialogContent className="fundsModalContent">
          <Box className="fundsHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ORDERS.DETAIL.OVERVIEW.ADD_FUND_AND_PROCESS_ORDER}
            </Typography>
          </Box>
          <Box className="amountInputWrapper">
            <InputLabel className="fundsLabel">{MESSAGES.ACCOUNT.WALLET.STRIPE.ENTER_AMOUNT}:</InputLabel>
            <Input
              className="fundsInputField"
              type="text"
              placeholder="$10,000.00"
              value={fund ? `$${(fund)}` : (fund)}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
            {error && <Typography className="errorMessage">
              {error}
            </Typography>}
            <Box className="amountsWrapper">
              <Typography onClick={() => handleChangeInput(500, 1)} className={`amountBtn ${activeTab === 1 ? 'activeAmount' : ''}`}>$500</Typography>
              <Typography onClick={() => handleChangeInput(1000, 2)} className={`amountBtn ${activeTab === 2 ? 'activeAmount' : ''}`}>$1,000</Typography>
              <Typography onClick={() => handleChangeInput(2500, 3)} className={`amountBtn ${activeTab === 3 ? 'activeAmount' : ''}`}>$2,500</Typography>
              <Typography onClick={() => handleChangeInput(5000, 4)} className={`amountBtn ${activeTab === 4 ? 'activeAmount' : ''}`}>$5,000</Typography>
              <Typography onClick={() => handleChangeInput(10000, 5)} className={`amountBtn ${activeTab === 5 ? 'activeAmount' : ''}`}>
                $10,000
              </Typography>
            </Box>

         
           
            <FormControlLabel
              className="fundCheck"
              control={
                <Checkbox
                  className={`customCheckbox checked`}
                  // onClick={() => handleCheckboxChange(content.id)}
                  icon={<Box className="notChecked"></Box>}
                  checked={isAutoFundingEnabled}
                  style={{ pointerEvents: "auto" }}
                  disableTypography={true}
                  onClick={(e) => {
                    if (e.detail == 1) {
                      setIsAutoFundingEnabled(!isAutoFundingEnabled)
                    }
                  }
                  }
                  checkedIcon={<DoneIcon />}
                />
              }
              label={
                <Typography className="termsCheck">
                  {MESSAGES.ACCOUNT.WALLET.STRIPE.ENABLE_AUTO_FUND}
                  <span >
                    <Tooltip
                      title={
                        <>
                          <Typography>
                            {MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING_TITLE_1}
                          </Typography>
                          <Typography style={{ marginTop: "5px" }}>
                            {MESSAGES.ACCOUNT.WALLET.STRIPE.AUTO_FUNDING_TITLE_2}
                          </Typography>
                        </>
                      }
                      arrow
                      PopperProps={{
                        style: {
                          width: '240px',
                          height: '113px',
                        },
                      }}
                    >
                      <img src={InfoWalletCIcon} alt="info" />
                    </Tooltip>
                  </span>
                </Typography>
              }
            />
            {isAutoFundingEnabled && (
              <Box>
                <InputLabel className="fundsLabel">
                  {MESSAGES.ACCOUNT.WALLET.STRIPE.ENTER_MIN_AUTO_AMOUNT}:
                </InputLabel>
                <Input
                  className="fundsInputField"
                  type="text"
                  placeholder="$10,000.00"
                  value={autoFunds ? `$${autoFunds}` : autoFunds}
                  onChange={(e) =>
                    handleChangeInput(e.target.value, false, true)
                  }
                />
                {autoFundError && (
                  <Typography className="errorMessage">
                    {autoFundError}
                  </Typography>
                )}
              </Box>
            )}
        
          <Divider className="dividerFunds"/>
          {respError && <Typography className="fundsModalContent__error">{respError} </Typography>}
          </Box>
          <Box className='infoTxt'>
            <Typography>Your order will be resubmitted and processed. Your order is scheduled for {dateFormat(currentDate, true)}</Typography>
          </Box>
          <Box className="fundsModalBtns">
            <Button
              onClick={handleClose}
            >
              {MESSAGES.ACCOUNT.WALLET.STRIPE.CANCEL}
            </Button>
            <Button
              disabled={loading}
              onClick={handleAddFunding}
            >{
                loading ? <CircularProgress
                  sx={{
                    color: "white",
                    width: "25px !important",
                    height: "25px !important",
                  }}
                /> : MESSAGES.ORDERS.DETAIL.OVERVIEW.PAY_AND_PROCESS_BUTTON
              }

            </Button>
          
          </Box>
          <hr></hr>
          
        
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default AddFunds;
