import request from "~/utils/request";
import {
  GET_PRODUCTS,
  GET_ALL_TEMPLATES,
  CLEAR_FIELDS,
  TEMPLATE_PAGINATION_CHANGE,
  TEMPLATE_SEARCH,
  CLEAR_ALL_TEMPLATE,
  GET_DYNAMIC_FIELDS_FROM_SERVER,
  SET_PRODUCT_DETAILS,
} from "./action-types";

/**
 * Retrieves a list of templates from a server using an HTTP GET request.
 *
 * @param {number} [page=1] - The page number of the templates to retrieve.
 * @param {number} [pageSize=10] - The number of templates per page.
 * @returns {Promise<void>} - A promise that resolves when the action is dispatched.
 */
const getAllTemplates =
  (
    page = 1,
    pageSize = 10,
    search = "",
    productTypes = "",
    creator = "",
    templateType = "",
    productIds = [],
    refresh = true,
    isShared = false
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TEMPLATE_PAGINATION_CHANGE,
        payload: { data: { page, pageSize, loading: true } },
      });
      const { data } = await request.get("templates", {
        page,
        pageSize,
        search,
        creator,
        templateType,
        productIds,
        isShared,
      });
      dispatch({
        type: GET_ALL_TEMPLATES,
        payload: { data: data.data, refresh },
      });
      dispatch({
        type: TEMPLATE_PAGINATION_CHANGE,
        payload: { data: { page, pageSize, loading: false } },
      });
    } catch (error) {}
  };

/**
 * Makes an HTTP GET request to the 'templates' endpoint and dispatches an action with the retrieved data.
 * @param {number} id - The ID of the template to retrieve.
 * @param {function} dispatch - A function used to dispatch actions to the Redux store.
 * @returns {void}
 */
const getOneTemplate = async (id) => {
  try {
    const response = await request.get(`templates/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Deletes a template by its ID.
 *
 * @param {string} id - The unique identifier of the template to delete.
 * @returns {Promise} A Promise that resolves to the response indicating
 *                    the success of the template deletion, or rejects
 *                    with an error response in case of failure.
 *
 * @throws {Error} If the request to delete the template fails.
 */
const deleteTemplate = async (id) => {
  try {
    const response = await request.delete(`templates/${id}`);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Retrieves the view proof for a template with the given ID.
 *
 * @param {string} id - The ID of the template for which the view proof is requested.
 * @returns {Promise<object>} - The response object from the GET request, containing the view proof for the template with the given ID.
 * @throws {object} - The error response if there is an error.
 */
const getViewProof = async (id) => {
  try {
    const response = await request.get(`templates/${id}/view-proof`);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getViewProofForOrder = async (payload) => {
  try {
    const response = await request.post("orders/view-proof", payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

const getViewProofForAdminOrder = async (payload) => {
  try {
    const response = await request.post("admin/orders/view-proof", payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Duplicates a template by its ID.
 *
 * @param {string} id - The unique identifier of the template to duplicate.
 * @param {Object} data - Additional data to be sent with the duplicate request.
 * @returns {Promise} A Promise that resolves to the response containing information
 *                    about the duplicated template, or rejects with an error response
 *                    in case of failure.
 *
 * @throws {Error} If the request to duplicate the template fails.
 */

const doublicateTemplate = async (id, data) => {
  try {
    const response = await request.post(`templates/${id}/duplicate`, data);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Fetches products and dispatches an action with the product data to the Redux store.
 *
 * @param {Function} dispatch - The dispatch function from the Redux store.
 * @returns {Promise} A Promise that resolves after dispatching the action,
 *                    or rejects if there is an error fetching the products.
 *
 * @throws {Error} If there is an error fetching the products.
 */
const getAllProducts = () => async (dispatch) => {
  try {
    const response = await request.get("/products/types");
    dispatch({
      type: GET_PRODUCTS,
      payload: { products: response.data.data },
    });
  } catch (error) {}
};

const getAllCustomFields = () => async (dispatch) => {
  try {
    const response = await request.get("custom-fields");
    if (response.status === 200) {
      const data = response.data.data.reduce((acc, curr) => {
        acc[curr.key.replace(/{{|}}/g, "")] = curr;
        return acc;
      }, {});

      dispatch({ type: GET_DYNAMIC_FIELDS_FROM_SERVER, payload: { data } });
    }
  } catch (error) {
    return error.response;
  }
};

const getProductDetails = (payload) => async (dispatch) => {
  try {
    const response = await request.post("/products/template/details", payload);
    if (response.status === 200) {
      dispatch({ type: SET_PRODUCT_DETAILS, payload: response.data.data });
    }
  } catch (error) {
    return error.response;
  }
};

const getAllTemplateCategories = async () => {
  try {
    const response = await request.get("templates/categories");
    return response;
  } catch (error) {
    return error.response;
  }
};

const getTemplatesByTab = async (payload) => {
  try {
    const response = await request.post("/templates/by-tab", payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

/**
 * Uploads a template using the provided template form data.
 *
 * @param {FormData} - The form data containing the template files to upload.
 * @returns {Promise} - A promise that resolves with the response from the server.
 * @throws {Error} - If an error occurs during the upload process.
 */
const uploadTemplate = async (formData) => {
  try {
    const response = await request.post("templates/upload", formData);
    const uploadData = response.data?.data || {};

    return uploadData;
  } catch (error) {
    return error.response;
  }
};

/**
 * Creates a template using the provided data.
 *
 * @param {object} data - The data needed to create the template.
 * @returns {Promise<object>} - A promise that resolves to the response from the successful request or the error response if an error occurs.
 */
const createTemplate = async (formData) => {
  try {
    const payload = {};
    for (const pair of formData.entries()) {
      let value;
      try {
        value = JSON.parse(pair[1]);
      } catch (e) {
        value = pair[1]; // If it's not JSON, use the value as is
      }
      let key = pair[0];
      payload[key] = value;
    }

    const uploadedTemplate = await uploadTemplate(formData);

    const templatePayload = {
      title: payload.title,
      productId: payload.productId,
      fields: payload.fields || [],
      thumbnailPath: uploadedTemplate.thumbnailPath,
      templatePath: uploadedTemplate.templatePath,
      backThumbnailPath: uploadedTemplate.backThumbnailPath || "",
      ...(payload.envelopeType && { envelopeType: payload.envelopeType }),
    };

    const response = await request.post("templates", templatePayload);
    return response;
  } catch (error) {
    throw error.response;
  }
};

/**
 * Update a template using the provided data.
 * @param {string} id - The id of the template
 * @param {object} data - The data needed to update the template.
 * @returns {Promise<object>} - A promise that resolves to the response from the successful request or the error response if an error occurs.
 */
const updateTemplate = async (id, formData) => {
  try {
    const payload = {};
    for (const pair of formData.entries()) {
      let value;
      try {
        value = JSON.parse(pair[1]);
      } catch (e) {
        value = pair[1]; // If it's not JSON, use the value as is
      }
      let key = pair[0];
      payload[key] = value;
    }

    const uploadedTemplate = await uploadTemplate(formData);
    console.log("payload", payload);
    const templatePayload = {
      title: payload.title,
      fields: payload.fields || [],
      thumbnailPath: uploadedTemplate.thumbnailPath,
      templatePath: uploadedTemplate.templatePath,
      backThumbnailPath: uploadedTemplate.backThumbnailPath || "",
    };

    const response = await request.patch(`templates/${id}`, templatePayload);
    return response;
  } catch (error) {
    throw error.response;
  }
};

const clearAllTemplates = () => (dispatch) => {
  dispatch({ type: CLEAR_ALL_TEMPLATE });
};

const searchAndAdvanceChange = (name, value) => (dispatch) => {
  dispatch({ type: TEMPLATE_SEARCH, payload: { name, value } });
};

const clearFilter = () => (dispatch) => dispatch({ type: CLEAR_FIELDS });

export {
  getAllProducts,
  getAllTemplates,
  getOneTemplate,
  clearFilter,
  deleteTemplate,
  getViewProof,
  doublicateTemplate,
  searchAndAdvanceChange,
  clearAllTemplates,
  getAllCustomFields,
  getProductDetails,
  getViewProofForOrder,
  getViewProofForAdminOrder,
  getAllTemplateCategories,
  getTemplatesByTab,
  createTemplate,
  updateTemplate,
};
