import request from '~/utils/request';

export const getOneDesignerTemplateQuery = async id => {
  try {
    return request.get(`admin/custom-template-queries/${id}/template-details`);
  } catch (error) {
    return error.response;
  }
};

export const updateTemplateQuery = async (id, formData) => {
  try {
    return request.post(`admin/custom-template-queries/${id}/update-template`, formData);
  } catch (error) {
    return error.response;
  }
};

export const publishTemplateQuery = async data => {
  const meta = data?.meta || {};

  const payload = {
    status: 'Published'
  };

  if (meta?.templatePath) payload.templatePath = meta?.templatePath;
  if (meta?.backTemplatePath) payload.backTemplatePath = meta?.backTemplatePath;
  if (meta?.thumbnailPath) payload.thumbnailPath = meta?.thumbnailPath;
  if (meta?.backThumbnailPath) payload.backThumbnailPath = meta?.backThumbnailPath;

  try {
    return request.post(`admin/custom-template-queries/${data.id}/update-query`, payload);
  } catch (error) {
    return error.response;
  }
};
