//Actions
import {
    SET_ADMIN_QUERIES_DATA, SET_ADMIN_QUERIES_LOADER,
    SET_ADMIN_QUERY_DETAILS_DATA,
    SET_ADMIN_QUERY_DETAILS_LOADER,
} from "../actions/action-types"

const initialState = {
    queries: [],
    rowsCount: 0,
    to: null,
    from: null,
    lastPage: null,
    currentPage: null,
    perPage: 20,
    totalBatchesInDB: null,
    filterslabels: [],
    loading: false,
    queryDetailsLoader: false,
    applyFilters: false,
    filterSize: null,
    totalRevenue: 0,
    totalOrders: 0,
    filters: {}
};

const adminQueriesReducers = (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_ADMIN_QUERIES_DATA:
            return {
                ...state, 
                queries: payload.rows.rows, 
                rowsCount: payload.count, 
                totalQueriesInDB: payload.totalRecordsInDB,
                to: payload.to, 
                from: payload.from, 
                lastPage: payload.lastPage, 
                currentPage: payload.currentPage, 
                perPage: payload.perPage,
                // totalRevenue: payload.totalRevenue, 
                // totalOrders: payload.totalOrders
            };
        case SET_ADMIN_QUERY_DETAILS_DATA:
            return {
                ...state,
                queryDetails: payload
            }
        case SET_ADMIN_QUERIES_LOADER:
            return { ...state, loading: payload };
        case SET_ADMIN_QUERY_DETAILS_LOADER:
            return { ...state, queryDetailsLoader: payload };
        // case SET_ADMIN_ORDER_DISPATCH_LOADER:
        //     return { ...state, dispatchLoadingOrderId: payload, };
        // case SET_ADMIN_ORDERS_FILTERS_LABELS:
        //     return { ...state, filterslabels: payload };
        // case SET_ADMIN_ORDERS_FILTERS:
        //     return { ...state, applyFilters: payload.applyFilters, filterSize: payload.filterSize, filters: payload.filters, };
        // case CLEAR_ADMIN_ORDERS_FILTERS:
        //     return { ...state, applyFilters: false, filterSize: null, filters: {} };
        // case LOGOUT:
        //     return initialState;
        default:
            return state;
    }
};

export { adminQueriesReducers };